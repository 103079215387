import styled from "@xstyled/styled-components";

import { CandidateCardFragment } from "@hire/schema";
import { pxToRem, borderRadius } from "@otta/design-tokens";
import { Text, VerticalSpacing } from "@otta/design";
import { Separator } from "@hire/components/Separator";

const Background = styled.div`
  padding: lg md;
  background-color: yellow-100;
  border-radius: ${pxToRem(borderRadius)};
`;

interface CandidateContextQuestionResponsesProps {
  candidate: CandidateCardFragment;
}

export function CandidateContextQuestionResponses({
  candidate,
}: CandidateContextQuestionResponsesProps): React.ReactElement | null {
  if (candidate.contextQuestionResponses.length === 0) {
    return null;
  }

  return (
    <>
      <Separator />
      <Background>
        <VerticalSpacing>
          {candidate.contextQuestionResponses.map(response => (
            <VerticalSpacing key={response.id} size={-2}>
              <Text size={-1} bold>
                {response.question.question}
              </Text>
              <Text data-testid="context-question-text" align="left" size={-1}>
                {response.value}
              </Text>
            </VerticalSpacing>
          ))}
        </VerticalSpacing>
      </Background>
    </>
  );
}
