import styled from "@xstyled/styled-components";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";

const CircleButton = styled.button`
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: none;
  background: none;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditButton = styled(CircleButton)`
  &:hover {
    background: ${palette.brand.grey};
  }
  svg {
    height: ${modularScale()};
    stroke: black;
  }
`;
