import styled, { css, up } from "@xstyled/styled-components";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { ConversationTypeFilter } from "./components/ConversationTypeFilter";

import { CompanyInternalJobApplicationsDocument } from "@hire/schema";
import { MicroCheckbox } from "@hire/components/MicroCheckbox";
import { palette, modularScale } from "@otta/design-tokens";
import { Clickable, Spacing } from "@otta/design";

const Container = styled.div<{ open: boolean }>`
  z-index: 2;
  background: ${palette.brand.white};
  padding: lg;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  will-change: transform;
  transition: default;
  transition-property: transform;
  transform: translateX(${({ open }) => (open ? "0" : "-100%")});

  ${up(
    "tablet",
    css`
      position: relative;
      grid-area: left;
      transform: translateX(0);
      border-right: 1px solid ${palette.grayscale.shade200};
    `
  )}
`;

const TabsContainer = styled.div`
  display: flex;
  gap: lg;
`;

const Tab = styled(Clickable)`
  font-size: ${modularScale()};
  color: black;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 5px solid transparent;
  padding-bottom: 6;
  cursor: pointer;
  &:hover {
    border-color: gray-200;
  }
  &.active {
    border-color: yellow-500;
  }
`;

export function InboxSideBar({
  open,
  filterAwaitingResponse,
  filterSender,
  showApplications,
  showSourced,
  showArchived,
  children,
}: {
  open: boolean;
  filterAwaitingResponse: boolean;
  filterSender: boolean;
  showApplications: boolean;
  showSourced: boolean;
  showArchived: boolean;
  children: React.ReactNode;
}): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useQuery(CompanyInternalJobApplicationsDocument);

  const receivesApplicationsWithinOtta =
    !!data?.currentUser?.currentCompany?.receivesApplicationsWithinOtta;

  const composeSearch = (key: string, value?: string | null) => {
    const p = new URLSearchParams(searchParams);

    if (value) {
      p.set(key, value);
    } else {
      p.delete(key);
    }

    setSearchParams(p);
  };

  return (
    <Container open={open}>
      <Spacing>
        <TabsContainer>
          <Tab
            className={!showArchived ? "active" : undefined}
            onClick={() => composeSearch("archived", undefined)}
          >
            Active
          </Tab>
          <Tab
            className={showArchived ? "active" : undefined}
            onClick={() => composeSearch("archived", "true")}
          >
            Archived
          </Tab>
        </TabsContainer>
        <ConversationTypeFilter
          composeSearch={composeSearch}
          receivesApplicationsWithinOtta={receivesApplicationsWithinOtta}
          showApplications={showApplications}
          showSourced={showSourced}
        />
        <MicroCheckbox
          label="Only show candidates I have messaged"
          data-analytics-id="messaging-filter-messaged-candidates"
          checked={filterSender}
          onChange={() =>
            composeSearch("sender", filterSender ? undefined : "me")
          }
        />
        <MicroCheckbox
          label="Show unread messages / new applications"
          data-analytics-id="messaging-filter-unactioned-candidates"
          checked={filterAwaitingResponse}
          onChange={() =>
            composeSearch(
              "unreplied",
              filterAwaitingResponse ? undefined : "true"
            )
          }
        />
        {children}
      </Spacing>
    </Container>
  );
}
