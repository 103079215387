import styled from "@xstyled/styled-components";

import { Icon } from "@otta/icons";
import { Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-weight: normal;
  color: black;
  :hover {
    text-decoration: none;
`;

export const ArticlePreview = ({
  title,
  url,
}: {
  title: string;
  url: string;
}) => {
  return (
    <Spacing size={-12}>
      <Text bold>{title}</Text>
      <StyledLink to={url} newTab underline size={-1}>
        {url} <Icon icon="external" />
      </StyledLink>
    </Spacing>
  );
};
