import { Founder } from "../../types";

import { FounderModal } from "./FounderModal";
import { FounderPreview } from "./FounderPreview";

import { EmptyList, ItemCardList } from "@hire/components/form/ItemCardList";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";

interface FounderSectionProps {
  founders: Founder[];
  onChange: (updatedFounders: Founder[]) => void;
}

export const FoundersSection = ({
  founders,
  onChange,
}: FounderSectionProps) => {
  return (
    <MoreInfoFieldWrapper
      label="Leadership"
      fieldName="founders"
      guidance="Include the current CEO, active founders and other key leaders."
    >
      <ItemCardList
        data={founders}
        name="founder"
        onChange={onChange}
        renderForm={props => <FounderModal {...props} />}
        renderPreview={founder => <FounderPreview {...founder} />}
        renderEmpty={() => (
          <EmptyList icon="user" text="Add founders to your profile" />
        )}
      />
    </MoreInfoFieldWrapper>
  );
};
