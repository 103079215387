import { NavLink } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { NAV_BAR_WIDTH } from "../constants";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Clickable, Text } from "@otta/design";

export const Nav = styled.nav<{ hasNewLogo?: boolean }>`
  z-index: 4;
  display: grid;
  grid-template-columns: ${({ hasNewLogo }) =>
    hasNewLogo ? "1fr" : "auto 1fr"};
  grid-template-areas: ${({ hasNewLogo }) =>
    hasNewLogo
      ? `"logo hamburger"
    "links links"`
      : `"hamburger logo"
    "links links"`};
  background: ${palette.brand.grey};
  position: relative;

  ${up(
    "tablet",
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      grid-template-columns: auto;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        "logo"
        "links";
    `
  )}
`;

export const Links = styled.div<{ open?: boolean }>`
  z-index: 4;
  overflow: auto;
  grid-area: links;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: calc(100vh - ${pxToRem(NAV_BAR_WIDTH)});
  z-index: 1;
  background: ${palette.brand.white};
  display: ${({ open }) => (open ? "flex" : "none")};
  border-right: 1px solid ${palette.grayscale.shade200};

  ${up(
    "tablet",
    css`
      display: flex;
      background: ${palette.brand.white};
      position: unset;
      height: unset;
      justify-content: space-between;
    `
  )}
`;

export const LinkListWrapper = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  gap: 1px;
  ${up(
    "tablet",
    css`
      gap: 0;
    `
  )}
`;

export const NavItem = styled(NavLink)`
  color: black;
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  gap: 19;
  padding: 19;

  ${up(
    "tablet",
    css`
      padding: 0;
      height: ${pxToRem(NAV_BAR_WIDTH)};
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: xs;
    `
  )}

  &:hover {
    background: ${palette.brand.grey};
  }

  &.active {
    background: ${palette.brand.yellow};
    font-weight: bold;
  }
`;

export const NavText = styled(Text)`
  font-size: ${modularScale(0)};

  ${up(
    "tablet",
    css`
      font-size: ${modularScale(-1)};
    `
  )}
`.withComponent("span");

export const WttjLogoContainer = styled.div`
  grid-area: logo;
  background: ${palette.brand.white};
  display: flex;
  align-items: center;

  ${up(
    "tablet",
    css`
      border-right: 1px solid ${palette.grayscale.shade200};
      padding-left: 0;
      justify-content: center;
      height: ${pxToRem(NAV_BAR_WIDTH)};
      width: ${pxToRem(NAV_BAR_WIDTH)};
    `
  )}

  &:hover {
    background: ${palette.brand.grey};
  }
`;

export const OttaLogoContainer = styled.div`
  grid-area: logo;
  background: ${palette.brand.white};
  display: flex;
  align-items: center;
  padding-left: 19;
  border-right: 1px solid ${palette.grayscale.shade200};

  ${up(
    "tablet",
    css`
      padding-left: 0;
      justify-content: center;
      height: ${pxToRem(NAV_BAR_WIDTH)};
      width: ${pxToRem(NAV_BAR_WIDTH)};
    `
  )}

  &:hover {
    background: ${palette.brand.grey};
  }
`;

export const Hamburger = styled(Clickable)`
  grid-area: hamburger;
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  background: ${palette.brand.white};
  display: flex;
  align-items: center;
  justify-content: center;

  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

export const HamburgerControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: xs;
`;

const HamburgerSlice = styled.div`
  display: block;
  width: ${pxToRem(32)};
  height: ${pxToRem(4)};
  background: ${palette.brand.black};
  transition: default;
  will-change: transform;
`;

export const HamburgerControlTop = styled(HamburgerSlice)<{ open: boolean }>`
  transform: translateY(${({ open }) => (open ? "8px" : "0")})
    rotate(${({ open }) => (open ? "45deg" : "0deg")});
`;

export const HamburgerControlMiddle = styled(HamburgerSlice)<{
  open: boolean;
}>`
  transform: translateX(${({ open }) => (open ? "-100%" : "0")});
  opacity: ${({ open }) => (open ? "0" : "1")};
`;

export const HamburgerControlBottom = styled(HamburgerSlice)<{
  open: boolean;
}>`
  transform: translateY(${({ open }) => (open ? "-8px" : "0")})
    rotate(${({ open }) => (open ? "-45deg" : "0deg")});
`;
