import { useMemo } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { AdminRecruiterCard } from "./AdminRecruiterCard";
import { MemberRecruiterCard } from "./MemberRecruiterCard";

import { CompanyRecruiterFragment, Feature } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";
import { useAuthorization } from "@hire/providers/authorization";

const Separator = styled.hr<{ adminView?: boolean }>`
  color: gray-100;
  border: ${pxToRem(0.5)} solid;
  margin: 15 0;
  grid-column: 1 / ${({ adminView }) => (adminView ? 5 : 4)};
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Grid = styled.div<{ adminView?: boolean }>(
  ({ adminView }) => css`
    display: grid;
    grid-template-columns: ${adminView ? "1fr auto" : "1fr"};
    gap: lg;
    align-items: center;
    text-align: left;
    ${up(
      "tablet",
      css`
        grid-template-columns: ${adminView
          ? "1fr auto auto auto"
          : "1fr auto auto"};
      `
    )}
  `
);

export const TeamMemberList = ({
  currentUserId,
  companyName,
  recruiters,
}: {
  currentUserId: string;
  companyName: string;
  recruiters: CompanyRecruiterFragment[];
}) => {
  const { granted: administerUsers } = useAuthorization(
    Feature.EditTeamMembers
  );

  const sortedRecruiters = useMemo(
    () =>
      [...recruiters].sort((a, b) => {
        if (a.id === currentUserId) {
          return -1;
        }
        if (b.id === currentUserId) {
          return 1;
        }
        return a.firstName.localeCompare(b.firstName);
      }),
    [recruiters, currentUserId]
  );

  return (
    <Grid adminView={administerUsers}>
      <Text bold>Name</Text>
      <TooltipWrapper>
        <Text bold>Role</Text>
        <TooltipWithIcon
          content="Admins can change the role of other users, and remove them. Members cannot change roles for other users."
          icon={<Icon icon="circle-info" />}
        />
      </TooltipWrapper>
      <Text bold style={{ textAlign: administerUsers ? "left" : "right" }}>
        Last active
      </Text>
      {administerUsers && <Text bold>Actions</Text>}
      {sortedRecruiters.map((recruiter, index) => (
        <>
          {administerUsers ? (
            <AdminRecruiterCard
              key={`${recruiter.id}-${index}`}
              currentUserId={currentUserId}
              currentCompanyName={companyName}
              id={recruiter.id}
              firstName={recruiter.firstName}
              lastName={recruiter.lastName}
              email={recruiter.email}
              role={recruiter.role}
              profileImagePath={recruiter.profileImagePath}
              lastActive={recruiter.lastActive}
            />
          ) : (
            <MemberRecruiterCard
              key={`${recruiter.id}-${index}`}
              currentUserId={currentUserId}
              id={recruiter.id}
              firstName={recruiter.firstName}
              lastName={recruiter.lastName}
              email={recruiter.email}
              role={recruiter.role}
              profileImagePath={recruiter.profileImagePath}
              lastActive={recruiter.lastActive}
            />
          )}
          {index != sortedRecruiters.length - 1 && (
            <Separator adminView={administerUsers} />
          )}
        </>
      ))}
    </Grid>
  );
};
