import { useEffect } from "react";
import Linkify from "react-linkify";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { format, parseJSON } from "date-fns";

import { UpdateConversationMessageReadDocument } from "@hire/schema";
import { palette, pxToRem, borderRadius } from "@otta/design-tokens";
import { Text, Tooltip } from "@otta/design";

const MessageBubble = styled.div<{
  left: boolean;
  invertRecruiterMessageColor: boolean;
}>`
  max-width: 590px;
  word-wrap: break-word;
  color: black;
  background-color: ${({ left, invertRecruiterMessageColor }) =>
    left
      ? palette.extended.yellow.shade100
      : invertRecruiterMessageColor
      ? palette.beige.shade200
      : palette.brand.white};
  padding: md;
  border-radius: ${pxToRem(borderRadius)};
  width: 80%;
  white-space: pre-wrap;
`;

interface ConversationMessageProps {
  fromCandidate: boolean;
  read?: boolean;
  id: string;
  insertedAt: string | null;
  children: React.ReactNode;
  author: string;
  invertRecruiterMessageColor: boolean;
}

export function ConversationMessage({
  id,
  fromCandidate,
  read,
  children,
  insertedAt,
  author,
  invertRecruiterMessageColor,
}: ConversationMessageProps): React.ReactElement {
  const [updateMessageViewed] = useMutation(
    UpdateConversationMessageReadDocument
  );

  useEffect(() => {
    if (id && fromCandidate && read === false) {
      updateMessageViewed({ variables: { messageId: id, read: true } });
    }
    // we only want to mark read on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromCandidate, id, updateMessageViewed]);

  const timestamp = format(
    insertedAt ? parseJSON(insertedAt) : new Date(),
    "EEE MMM do HH:mm"
  );

  const tooltipContent = (
    <>
      <Text size={-1}>{timestamp}</Text>
      <Text size={-1}>{author}</Text>
    </>
  );

  return (
    <Linkify>
      <Tooltip
        content={tooltipContent}
        placement={fromCandidate ? "right" : "left"}
      >
        <MessageBubble
          data-testid="message-bubble"
          left={!fromCandidate}
          invertRecruiterMessageColor={invertRecruiterMessageColor}
        >
          {children}
        </MessageBubble>
      </Tooltip>
    </Linkify>
  );
}
