import { NavLink } from "react-router-dom";
import styled, { css, down, up } from "@xstyled/styled-components";

import { Section } from "./types";

import { Loading } from "@otta/shared-components";
import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing } from "@otta/design";

const Container = styled.div`
  display: none;
  height: 100vh;
  position: sticky;
  top: 0;

  ${up(
    "tablet",
    css`
      display: flex;
    `
  )}
`;

const SubNav = styled.div`
  height: 100%;
  padding: lg;
  z-index: 1;
  background: ${palette.brand.white};
  min-width: ${pxToRem(248)};
  border-right: 1px solid ${palette.grayscale.shade200};
  ${up(
    "tablet",
    css`
      height: 100%;
    `
  )}
  ${down(
    "tablet",
    css`
      border-right: none;
      border-bottom: 1px solid ${palette.grayscale.shade200};
    `
  )}
`;

const NavList = styled.ul`
  list-style-type: none;
`;

const NavListItem = styled.li`
  padding-bottom: sm;
`;

const StyledLink = styled(NavLink)`
  display: flex;
  user-select: none;
  height: ${pxToRem(44)};
  align-items: center;
  cursor: pointer;
  color: black;
  text-decoration: none;
  padding-left: md;
  margin-right: 28;
  gap: xs;
  border-left: 4px solid transparent;

  &:hover {
    border-color: gray-200;
  }

  &.active {
    border-color: yellow-500 !important;
    cursor: default;
    font-weight: 700;
  }
`;

export const SubNavBar = ({
  "data-testid": testId,
  loading,
  sections,
  children,
}: React.PropsWithChildren<{
  "data-testid"?: string;
  loading?: boolean;
  sections: Section[];
}>) => {
  return (
    <Container data-testid={testId}>
      <SubNav>
        {loading ? (
          <Loading />
        ) : (
          <Spacing>
            {sections.map((section, index) => (
              <NavList key={index}>
                {section.links.map(link => {
                  const analyticsProps =
                    link.title === "Your year on Otta"
                      ? { "data-analytics-id": "year-on-otta-nav-item" }
                      : {};
                  return (
                    <NavListItem key={link.to}>
                      <StyledLink
                        to={link.to}
                        title={link.title}
                        target={link.target}
                        {...analyticsProps}
                      >
                        {link.content}
                        {link.badge}
                      </StyledLink>
                    </NavListItem>
                  );
                })}
                {children}
              </NavList>
            ))}
          </Spacing>
        )}
      </SubNav>
    </Container>
  );
};
