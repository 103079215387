import styled from "@xstyled/styled-components";

import { RangeSlider } from "@otta/shared-components";
import { Text, responsive } from "@otta/design";

// Has to be this odd size to fit nicely
const EntryLabel = styled(Text)<{ selected?: boolean }>`
  width: 75px;
  color: black;
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  ${responsive`font-size: ${responsive.modularScale({
    mobile: -1.75,
    desktop: -1,
  })};`}
`;

export type Year = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type Category = 0 | 1 | 2 | 3 | 4;

const marks = (values: number[], shortForm: boolean) => {
  const inRange = (value: number) => {
    return value >= values[0] && value <= values[1];
  };

  if (shortForm) {
    return {
      0: <EntryLabel selected={inRange(0)}>Entry</EntryLabel>,
      1: <EntryLabel selected={inRange(1)}>Junior</EntryLabel>,
      2: <EntryLabel selected={inRange(3)}>Mid</EntryLabel>,
      3: <EntryLabel selected={inRange(8)}>Senior</EntryLabel>,
      4: <EntryLabel selected={inRange(10)}>Expert</EntryLabel>,
    };
  }

  return {
    0: (
      <EntryLabel selected={inRange(0)}>
        Entry-level <br />
        or graduate
      </EntryLabel>
    ),
    1: (
      <EntryLabel selected={inRange(1)}>
        Junior <br />
        (1-2 years)
      </EntryLabel>
    ),
    2: (
      <EntryLabel selected={inRange(3)}>
        Mid-level <br />
        (3-4 years)
      </EntryLabel>
    ),
    3: (
      <EntryLabel selected={inRange(8)}>
        Senior <br /> (5-8 years)
      </EntryLabel>
    ),
    4: (
      <EntryLabel selected={inRange(10)}>
        Expert and <br />
        leadership <br />
        (9+ years)
      </EntryLabel>
    ),
  };
};

const ExperienceSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledRangeSlider = styled(RangeSlider)`
  margin-top: sm;
`;

const yearToCategory: Record<Year, Category> = {
  0: 0,
  1: 1,
  2: 1,
  3: 2,
  4: 2,
  5: 3,
  6: 3,
  7: 3,
  8: 3,
  9: 4,
  10: 4,
};
const yearsToCategories = ([lowerBound, upperBound]: [Year, Year]): [
  Category,
  Category
] => {
  const upperBoundCategory =
    upperBound > 10 ? yearToCategory[10] : yearToCategory[upperBound];
  return [yearToCategory[lowerBound], upperBoundCategory];
};

export const yearsToCategoryLabels = ([lowerBound, upperBound]: [
  Year,
  Year
]): string[] => {
  const categories = yearsToCategories([lowerBound, upperBound]);
  return categories.map(category => {
    switch (category) {
      case 0:
        return "Entry";
      case 1:
        return "Junior";
      case 2:
        return "Mid";
      case 3:
        return "Senior";
      case 4:
        return "Expert";
    }
  });
};

const lowerCategoryToYear: Record<Category, Year> = {
  0: 0,
  1: 1,
  2: 3,
  3: 5,
  4: 9,
};
const upperCategoryToYear: Record<Category, Year> = {
  0: 0,
  1: 2,
  2: 4,
  3: 8,
  4: 10,
};

const categoriesToYears = ([lowerBound, upperBound]: [Category, Category]): [
  Year,
  Year
] => [lowerCategoryToYear[lowerBound], upperCategoryToYear[upperBound]];

interface ExperienceSliderProps {
  value: [Year, Year];
  onChange: (newValue: [Year, Year]) => void;
  onAfterChange?: (newValue: [Year, Year]) => void;
  disabled?: boolean;
  shortForm?: boolean;
}

export function ExperienceSlider({
  value,
  onChange,
  onAfterChange,
  disabled = false,
  shortForm = false,
}: ExperienceSliderProps): React.ReactElement {
  return (
    <ExperienceSliderContainer data-testid="experience-slider">
      <StyledRangeSlider
        allowCross={false}
        min={0}
        max={4}
        step={1}
        marks={marks(value, shortForm)}
        value={yearsToCategories(value)}
        disabled={disabled}
        onChange={values => {
          onChange(categoriesToYears(values as [Category, Category]));
        }}
        onAfterChange={values => {
          if (!onAfterChange) {
            return;
          }

          onAfterChange(categoriesToYears(values as [Category, Category]));
        }}
      />
    </ExperienceSliderContainer>
  );
}
