import styled from "@xstyled/styled-components";
import { useRef, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { FakeProfileModal } from "../FakeProfileModal";

import { ConversationMessage } from "./ConversationMessage";

import {
  ConversationMessagesDocument,
  UpdateConversationMessageReadDocument,
  OneInboxConversationFragment,
} from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing, VerticalSpacing, Text, Button } from "@otta/design";
import { useRequiredParams } from "@hire/util/routing";
import { useTabVisible } from "@hire/util/hooks/useTabVisible";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";

const OuterContainer = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 100%;
`;

const MessageToolbar = styled.div`
  background-color: white;
  border-bottom: 1px solid ${palette.brand.grey};
  padding: sm;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const Container = styled.div`
  grid-area: content;
  overflow-y: scroll;
  padding: xl;
  padding-top: 56;
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  z-index: 0;
`;

const MessageWrapper = styled.div<{ sent: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ sent }) => (sent ? "flex-end" : "flex-start")};
`;

const UnderTextComponent = styled.span`
  font-size: ${pxToRem(12)};
  right: ${pxToRem(10)};
  bottom: ${pxToRem(-16)};
  text-align: left;
  color: black;
`;

const ReportButton = styled(Button)`
  margin: 0 10;
  background-color: white;
`;

function UnderText({
  firstName,
  accepted,
  hasApplication,
}: {
  firstName: string;
  accepted: boolean | null;
  hasApplication: boolean;
}): React.ReactElement | null {
  if (accepted === true || hasApplication) {
    return (
      <UnderTextComponent data-cs-mask>
        {firstName} accepted your message request.
      </UnderTextComponent>
    );
  }

  if (accepted === null) {
    return (
      <UnderTextComponent data-cs-mask>
        {firstName} has not yet responded.
      </UnderTextComponent>
    );
  }

  return null;
}

const POLL_INTERVAL = 15000;

export function ConversationMessages({
  conversation,
  inPipeline,
}: {
  conversation: OneInboxConversationFragment;
  inPipeline: boolean;
}): React.ReactElement {
  const [showModal, setShowModal] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { jobId, candidateId } = useRequiredParams(["jobId", "candidateId"]);
  const redirectUrl = pathname.split(`candidates/${candidateId}`)[0];
  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery(
    ConversationMessagesDocument,
    {
      variables: {
        jobId,
        candidateId,
      },
      fetchPolicy: "network-only",
      pollInterval: POLL_INTERVAL,
    }
  );
  const messages = useMemo(() => {
    return data?.companyRecruiterConversation?.messages ?? [];
  }, [data?.companyRecruiterConversation?.messages]);

  const latestMessageFromCandidate = useMemo(() => {
    return messages
      .filter(msg => {
        return msg.sender.id === conversation.recipient.id;
      })
      .sort((a, b) => {
        return +new Date(b.insertedAt) - +new Date(a.insertedAt);
      })[0];
  }, [conversation.recipient.id, messages]);

  const [markUnreadMutation] = useMutation(
    UpdateConversationMessageReadDocument,
    {
      onCompleted: () => {
        navigate(redirectUrl);
      },
    }
  );

  const visible = useTabVisible();

  useEffect(() => {
    if (visible) {
      startPolling(POLL_INTERVAL);
    }
    return () => {
      stopPolling();
    };
  }, [visible, startPolling, stopPolling]);

  const messagesEnd = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof messagesEnd?.current?.scrollIntoView === "function") {
      messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages.length]);

  if (loading) {
    return <Loading />;
  }

  if (error || !conversation) {
    return <ReloadableError action={refetch} />;
  }

  return (
    <OuterContainer>
      <MessageToolbar>
        <Button
          level="tertiary"
          type="button"
          size="small"
          name="Mark as unread"
          onClick={() => {
            markUnreadMutation({
              variables: {
                messageId: latestMessageFromCandidate?.id,
                read: false,
              },
            });
          }}
        >
          <Text as="span" bold>
            Mark as unread
          </Text>
        </Button>
        {!inPipeline && (
          <ReportButton
            level="destructive"
            type="button"
            size="small"
            name="Report fake profile"
            onClick={() => {
              setShowModal(() => true);
            }}
          >
            <Text as="span" bold>
              Report fake profile
            </Text>
          </ReportButton>
        )}
      </MessageToolbar>
      <Container>
        <Spacing size={-1}>
          {messages.map(({ id, sender, content, read, insertedAt }, index) => {
            const fromCandidate = sender.id === conversation.recipient.id;
            const isFirstMessage = index === 0;
            const senderName = `${sender.firstName} ${sender.lastName}`;

            return (
              <MessageWrapper
                key={id}
                sent={!fromCandidate}
                data-testid="conversation-message"
              >
                <ConversationMessage
                  id={id}
                  read={read}
                  fromCandidate={fromCandidate}
                  insertedAt={insertedAt}
                  author={senderName}
                  invertRecruiterMessageColor={!!true}
                >
                  <VerticalSpacing>
                    <Text bold>{senderName}</Text>
                    <Text>{content}</Text>
                  </VerticalSpacing>
                </ConversationMessage>
                {conversation.sourced && isFirstMessage && !fromCandidate && (
                  <UnderText
                    data-cs-mask
                    firstName={conversation.recipient.firstName}
                    hasApplication={!!conversation.jobApplication}
                    accepted={conversation.hasAcceptedRequest}
                  />
                )}
              </MessageWrapper>
            );
          })}
        </Spacing>
        <div ref={messagesEnd} />
        <FakeProfileModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          jobId={conversation.job.id}
          suspectEmail={conversation.recipient.email}
        />
      </Container>
    </OuterContainer>
  );
}
