import styled from "@xstyled/styled-components";
import { useMedia } from "react-use";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import { RectangleTag } from "@hire/components/tags/RectangleTag";
import { Heading, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { Icon, OttaIconsId } from "@otta/icons";
import { ANALYTICS_DESKTOP_BREAKPOINT } from "@hire/components/layout/constants";

const heightAnimationDelay = 0.2;

const Row = styled.div`
  flex-grow: 1;
  display: flex;
  gap: sm;
  justify-content: space-between;
`;

const Column = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: sm;
`;

const CardStatistic = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: sm;
  border: 1px solid ${palette.grayscale.shade200};
  border-radius: ${pxToRem(8)};

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    padding: lg;
  }
`;

const Chevron = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(50%, -50%);
`;

const CardHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: sm;
`;

const CardBenchmark = styled.div<{ column?: boolean }>`
  align-items: ${({ column = false }) => (column ? "flex-start" : "flex-end")};
  display: flex;
  flex-direction: column;
  gap: xs;
`;

const ChildrenWrapper = styled.div`
  display: grid;
  margin-top: lg;
`;

const StatsWrapper = styled(motion.div)`
  border-top: 1px solid ${palette.brand.grey};
  padding: 20 0 0;
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: xxs;
`;

const Stat = styled.div`
  display: grid;
  grid-column: span 3;
  grid-template-columns: subgrid;
  margin: 0 -8;
  padding: xs sm;
  border-radius: ${pxToRem(8)};
  transition: default;

  /* :hover is not necessary here but it's snappier when you hover over the stat directly */
  /* see https://github.com/WTTJ/otta/pull/890#discussion_r1532178924 */
  &:hover,
  &[data-hovered="true"] {
    background-color: beige-200;
    transition: default;
  }
`;

const StatName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatValue = styled(Text).attrs({ as: "span" })`
  white-space: nowrap;
  text-align: right;
`;

type StatisticData = {
  id: string;
  total: number;
  percent: string;
  value: string;
};

export function Statistic({
  title,
  value,
  benchmark,
  unitDisplay = "%",
  conversion,
  isConversionPositive,
  column = false,
  showConversionHeading = true,
  filterData,
  hasChevron = true,
  children,
  hoveredItem,
  setHoveredItem,
}: {
  title: string;
  value?: number;
  benchmark?: string;
  unitDisplay?: string;
  conversion?: string;
  isConversionPositive?: boolean;
  column?: boolean;
  showConversionHeading?: boolean;
  filterData?: StatisticData[];
  hasChevron?: boolean;
  children?: React.ReactNode;
  hoveredItem?: string;
  setHoveredItem?: (value: string) => void;
}) {
  const isDesktop = useMedia(`(min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px)`);

  const hasColumnLayout = !isDesktop || column;
  const CardLayoutComponent = hasColumnLayout ? Column : Row;

  let dataTestId;
  let icon: OttaIconsId | undefined;
  let color;
  let iconColor;
  if (isConversionPositive) {
    dataTestId = "positive-card-benchmark";
    icon = "chevron-up-filled";
    color = palette.brand.green;
    iconColor = palette.extended.green.shade800;
  } else if (isConversionPositive === false) {
    dataTestId = "negative-card-benchmark";
    icon = "chevron-down-filled";
    color = palette.extended.red.shade200;
    iconColor = palette.extended.red.shade800;
  } else {
    dataTestId = "card-benchmark";
    color = palette.beige.shade200;
  }

  const hasFilterData = filterData && filterData.length > 0;
  // We need to measure the height of the filtered stats to animate it
  // We can't animate the height directly on a motion element because we want the height to animate between filters and not from 0
  const filteredStatsRef = useRef<HTMLDivElement | null>(null);
  const [filteredStatsHeight, setFilteredStatsHeight] = useState<
    number | "auto"
  >("auto");
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!filteredStatsRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      // We only have one entry, so we can use entries[0].
      const observedHeight = entries[0].contentRect.height;
      setFilteredStatsHeight(observedHeight);
    });

    resizeObserver.observe(filteredStatsRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // This is a hack to prevent a rendering bug that happens in Chrome 124
  // See https://linear.app/wttj/issue/OTTA-852/analytics-page-chart-is-overlapping-with-detailed-breakdown
  const handleAnimationComplete = () => {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.minHeight =
      wrapperRef.current.style.minHeight === "auto" ? "0" : "auto";
  };

  return (
    <CardStatistic data-testid={`card-statistic-${title}`} ref={wrapperRef}>
      {hasChevron && (
        <Chevron>
          <Icon icon="chevron-right" size={2} />
        </Chevron>
      )}
      <CardLayoutComponent>
        <CardHeading>
          <Text>{title}</Text>
          {value !== undefined && (
            <Heading align="left" size={2}>
              {value.toLocaleString()}
            </Heading>
          )}
        </CardHeading>
        {conversion && (
          <CardBenchmark data-testid={dataTestId} column={hasColumnLayout}>
            <RectangleTag
              icon={isDesktop ? icon : undefined}
              color={color}
              iconColor={iconColor}
              textColor={iconColor}
              content={
                <span>
                  {showConversionHeading && "Conversion: "}
                  <strong>
                    {conversion}
                    {unitDisplay}
                  </strong>
                </span>
              }
              bold={false}
            />
            {benchmark && (
              <RectangleTag
                color={palette.beige.shade200}
                content={`Benchmark: ${benchmark}${unitDisplay}`}
                bold={false}
              />
            )}
          </CardBenchmark>
        )}
      </CardLayoutComponent>

      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}

      <AnimatePresence mode="wait">
        <motion.div
          style={{
            height: filteredStatsHeight,
            marginTop: hasFilterData ? pxToRem(16) : 0,
          }}
          animate={{ height: filteredStatsHeight }}
          transition={{ duration: heightAnimationDelay }}
          onAnimationComplete={handleAnimationComplete}
        >
          <div ref={filteredStatsRef}>
            {hasFilterData && (
              <StatsWrapper
                key={filterData[0].id}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    opacity: {
                      delay: heightAnimationDelay,
                    },
                  },
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <Stats>
                  {filterData &&
                    filterData.map(section => (
                      <Stat
                        key={section.id}
                        data-hovered={hoveredItem === section.value}
                        onMouseEnter={() => setHoveredItem?.(section.value)}
                        onMouseLeave={() => setHoveredItem?.("")}
                      >
                        <StatName>{section.value}</StatName>
                        <StatValue bold>{`${section.total} `}</StatValue>
                        <StatValue>({section.percent}%)</StatValue>
                      </Stat>
                    ))}
                </Stats>
              </StatsWrapper>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </CardStatistic>
  );
}
