import styled from "@xstyled/styled-components";

import { VerticalSpacing, Text, Button, Modal } from "@otta/design";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  position: relative;
`;

const Container = styled.div`
  padding: xl;
`;

interface ApplicationModalProps {
  receivesApplicationsWithinOtta: boolean;
  showModal: boolean;
  handleClose: () => void;
}

export function ApplicationResponseModal({
  receivesApplicationsWithinOtta,
  handleClose,
  showModal,
}: ApplicationModalProps): React.ReactElement {
  return (
    <Modal open={showModal} dismissable={true} onOpenChange={handleClose}>
      <Container data-testid="application-response-modal">
        <VerticalSpacing size={2}>
          <Text bold>Calculating response rate</Text>
          <Text>
            {receivesApplicationsWithinOtta
              ? "Your 2 week response rate is calculated by the proportion of applications that receive a reply within 2 weeks."
              : "Your 2 week response rate is determined by the proportion of candidates who said they heard back from you within 2 weeks after submitting an application."}
          </Text>
          <Text>
            {receivesApplicationsWithinOtta
              ? "To keep things fair and timely, we calculate this based on the applications you have received in the last 90 days."
              : "To keep things fair and timely, we randomly select candidates who applied to your jobs in the last 90 days to collect this data."}
          </Text>
          <Text>
            Reply to all candidates within 2 weeks to maintain a high response
            rate.
          </Text>
          <Text>
            {receivesApplicationsWithinOtta
              ? "Your response rate is shown on your jobs to help candidates understand their likelihood of getting a quick reply to their application."
              : "This value is shown on your jobs to help candidates understand their likelihood of getting a quick reply to their application."}
          </Text>
          <ButtonWrapper>
            <Button
              data-testid="cancel-button"
              level="secondary"
              type="button"
              onClick={handleClose}
            >
              Go back to your dashboard
            </Button>
          </ButtonWrapper>
        </VerticalSpacing>
      </Container>
    </Modal>
  );
}
