import styled, { css, up } from "@xstyled/styled-components";

import { pxToRem, pxToEm, borderRadius } from "@otta/design-tokens";
import { Button, Spacing, Text } from "@otta/design";
import { Checked } from "@hire/components/icons/checklist/Checked";
import { Unchecked } from "@hire/components/icons/checklist/Unchecked";
import { Link } from "@hire/components/links";
import { useAuthorization } from "@hire/providers/authorization";
import { Feature } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40;
`;

const Table = styled.div`
  margin: 0 auto;
  padding: 28;
  padding-bottom: 0;
  background-color: white;
  color: black;
  border-radius: ${pxToRem(borderRadius)};

  ${up(
    "tablet",
    css`
      width: ${pxToEm(796)};
      padding: xxl;
    `
  )}
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: ${pxToRem(2)} solid #f0f0ed;
  padding: md 0;
  &:first-of-type,
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 19;
  }
  &:first-of-type {
    padding-bottom: 0;
  }
`;

const RowHeaderCell = styled.div`
  flex: 0 0 58%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: 5;

  ${up(
    "tablet",
    css`
      flex: 0 0 60%;
      &:first-child {
        justify-content: flex-start;
      }
    `
  )}
`;

const DataCell = styled.div<{ align: string }>`
  flex: 0 0 21%;
  display: flex;
  justify-content: ${({ align }) => align};
  align-items: center;

  ${up(
    "tablet",
    css`
      flex: 0 0 20%;
      justify-content: center;
    `
  )}
`;

const ColHeaderCell = styled(Text)`
  flex: 0 0 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button {
    margin: xs 0;
    width: ${pxToRem(260)};
  }

  ${up(
    "tablet",
    css`
      flex-direction: row;
      margin: 0 auto;
      width: 100%;
      button {
        margin: 0 sm;
        width: ${pxToEm(260)};
      }
    `
  )}
`;

const IconWrapper = styled.div`
  height: ${pxToRem(25)};
  width: ${pxToRem(25)};
`;

const contents = [
  {
    header: "Publish your jobs",
    basic: true,
    pro: true,
  },
  {
    header: "Receive high quality applications",
    basic: true,
    pro: true,
  },
  {
    header: "Enhanced company profile",
    basic: true,
    pro: true,
  },
  {
    header: "Unlimited hires across all functions and levels",
    basic: false,
    pro: true,
  },
  {
    header: "Boost the visibility of your jobs by 6x",
    basic: false,
    pro: true,
  },
  {
    header: "Exclusive access to sourcing",
    basic: false,
    pro: true,
  },
  {
    header: "Insights, benchmarks and advanced reporting",
    basic: false,
    pro: true,
  },
  {
    header: "Dedicated support from our expert team",
    basic: false,
    pro: true,
  },
];

const ComparisonTable: React.FC<{ jobSlotsEnabled: boolean }> = ({
  jobSlotsEnabled,
}) => (
  <Table>
    <Row>
      <RowHeaderCell />
      <ColHeaderCell bold size={2}>
        {jobSlotsEnabled ? "Core" : "Basic"}
      </ColHeaderCell>
      <ColHeaderCell bold size={2}>
        Pro
      </ColHeaderCell>
    </Row>
    {contents.map(({ header, basic, pro }) => (
      <Row key={header}>
        <RowHeaderCell>
          <Text>{header}</Text>
        </RowHeaderCell>
        <DataCell align="center">
          <IconWrapper> {basic ? <Checked /> : <Unchecked />}</IconWrapper>
        </DataCell>
        <DataCell align="center">
          <IconWrapper>{pro ? <Checked /> : <Unchecked />}</IconWrapper>
        </DataCell>
      </Row>
    ))}
  </Table>
);

function UpsellTemplate({
  campaign = "hire-app-other",
  header,
  body,
}: {
  header: string;
  body: React.ReactNode;
  campaign?: string;
}): React.ReactElement {
  const { granted: jobSlotsEnabled, loading } = useAuthorization(
    Feature.JobSlots
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Spacing size={3}>
        <Text bold size={2} align="center">
          {header}
        </Text>
        {body}
        <ButtonWrapper>
          <Button
            level="primary"
            as={Link}
            to={`/book-a-demo?utm_source=otta&utm_medium=jobs&utm_campaign=${campaign}&utm_id=hire_app&utm_term=book_a_demo`}
            underline={false}
            newTab
          >
            Book a demo
          </Button>
        </ButtonWrapper>
        <ComparisonTable jobSlotsEnabled={jobSlotsEnabled} />
      </Spacing>
    </Wrapper>
  );
}
export function ProUpsellPage({
  campaign,
}: {
  campaign?: string;
}): React.ReactElement {
  return (
    <UpsellTemplate
      header="Upgrade for access"
      body={
        <Text align="center">
          You do not have access to all of our premium features.
          <br />
          Upgrade to Pro to unlock these. Available for UK and/or EU jobs.
        </Text>
      }
      campaign={campaign}
    />
  );
}
export function PausedProUpsellPage({
  campaign = "hire-app-other",
}: {
  campaign?: string;
}): React.ReactElement {
  const companyName = useWelcomeToTheJungle();

  return (
    <UpsellTemplate
      campaign={campaign}
      header={`Your jobs have been removed from ${companyName}`}
      body={
        <Text align="center">
          The only way to ensure uninterrupted access to {companyName} is by
          upgrading to Pro.
        </Text>
      }
    />
  );
}
