import { NavLink } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

export const MobileSubNavItem = styled(NavLink)`
  border-left: 4px solid ${palette.brand.white};
  text-decoration: none;
  color: black;
  padding: md lg;
  background: ${palette.brand.white};
  &.active {
    border-left-color: yellow-500;
    font-weight: bold;
  }
`;
