import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";

const ToastText = styled.div`
  display: flex;
  gap: lg;
`;

const WhiteText = styled(Text)`
  color: white;
`;

const ClickableText = styled(WhiteText)`
  cursor: pointer;
  text-decoration: underline;
`;

interface BulkSelectToastProps {
  selectedItems: string[];
  handleBulkMessage: () => void;
  handleDeselectAll: () => void;
}

export const BulkSelectToast = ({
  selectedItems,
  handleBulkMessage,
  handleDeselectAll,
}: BulkSelectToastProps): React.ReactElement => {
  return (
    <ToastText>
      <WhiteText>{selectedItems.length} candidates selected</WhiteText>
      <ClickableText
        bold
        onClick={handleBulkMessage}
        data-analytics-id="bulk-message-candidates"
      >
        Message candidates
      </ClickableText>
      <ClickableText onClick={handleDeselectAll}>Deselect all</ClickableText>
    </ToastText>
  );
};
