import styled from "@xstyled/styled-components";

import { Blob, Blobs } from "../../common/Blob";

const VerticalBlobs = styled(Blobs)`
  flex-direction: column;
`;

export function CompanyState(): React.ReactElement | null {
  return (
    <VerticalBlobs>
      <Blob icon="clock" bold>
        Open for applications
      </Blob>
    </VerticalBlobs>
  );
}
