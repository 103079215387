import { useFormik } from "formik";
import { Link } from "react-router-dom";
import styled, { css, down } from "@xstyled/styled-components";
import * as Yup from "yup";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useContext, useMemo } from "react";

import { transformIn, transformOut } from "./transformers";
import { UserProfileFormFields } from "./types";

import {
  Button,
  InputField,
  SelectField,
  Spacing,
  Tipbox,
  Toggle,
} from "@otta/design";
import {
  CompaniesWithPreApprovalDocument,
  CurrentUserProfileQuery,
  Feature,
  UpdateCurrentUserDocument,
} from "@hire/schema";
import { UploadField } from "@hire/components/form/UploadField";
import { CircularImg } from "@hire/components/images/CircularImg";
import { modularScale, pxToRem } from "@otta/design-tokens";
import { handleMutationError } from "@hire/errors";
import { useAuthorization } from "@hire/providers/authorization";
import { SensitiveInfoContext } from "@hire/providers/SensitiveInfo";

const OuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: lg;
`;

const MainSection = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: xl;
  align-items: flex-start;
`;

const Inputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: lg;

  & > * {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - ${modularScale()});

    ${down(
      "tablet",
      css`
        flex-grow: 1;
        flex-basis: 100%;
      `
    )}
  }
`;

const SecondarySection = styled.div`
  flex-shrink: 1;
  flex-grow: 0;

  ${down(
    "tablet",
    css`
      flex-grow: 1;
      flex-basis: 100%;
    `
  )}
`;

const ProfilePhotoContainer = styled.div`
  background-color: gray-50;
  border-radius: ${pxToRem(24)};
  padding: lg;
`;

const StyledTipbox = styled(Tipbox).attrs({ level: "information" })`
  width: 100%;
  text-align: left;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`;

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(2)
    .required("Fill in this field")
    .label("First name"),
  lastName: Yup.string()
    .min(2)
    .required("Fill in this field")
    .label("Last name"),
  jobTitle: Yup.string()
    .min(2)
    .required("Fill in this field")
    .label("Job title"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Fill in this field"),
});

export const Form = ({
  data,
}: {
  data: NonNullable<CurrentUserProfileQuery["currentUser"]>;
}) => {
  const { granted: canChangeCurrentCompany } = useAuthorization(
    Feature.ChangeCompany
  );
  const { sensitiveFieldsHidden, setSensitiveInfoSetting } =
    useContext(SensitiveInfoContext);

  const { loading: companiesLoading, data: companiesData } = useQuery(
    CompaniesWithPreApprovalDocument,
    {
      skip: !canChangeCurrentCompany,
    }
  );

  const companyOptions = useMemo(
    () =>
      companiesData?.companies.map(c => ({ label: c.name, value: c.id })) ?? [],
    [companiesData]
  );

  const [update] = useMutation(UpdateCurrentUserDocument, {
    onError: handleMutationError,
  });

  const initialValues = useMemo(() => transformIn(data), [data]);

  const form = useFormik<UserProfileFormFields>({
    validationSchema,
    initialValues,
    onSubmit: (formData: UserProfileFormFields) =>
      update({ variables: { input: transformOut(formData) } }),
  });

  const client = useApolloClient();

  return (
    <OuterContainer>
      <MainSection onSubmit={form.handleSubmit}>
        <StyledTipbox>
          Your profile is used to personalise messages to candidates, so make
          sure it&apos;s up to date.
        </StyledTipbox>
        <Inputs>
          <InputField
            name="firstName"
            label="First name"
            disabled={form.isSubmitting}
            value={form.values.firstName}
            onChange={form.handleChange("firstName")}
            onBlur={form.handleBlur("firstName")}
            error={form.touched.firstName ? form.errors.firstName : undefined}
          />

          <InputField
            name="lastName"
            disabled={form.isSubmitting}
            value={form.values.lastName}
            label="Last name"
            onChange={form.handleChange("lastName")}
            onBlur={form.handleBlur("lastName")}
            error={form.touched.lastName ? form.errors.lastName : undefined}
          />

          <InputField
            name="email"
            disabled={form.isSubmitting}
            value={form.values.email}
            label="Email"
            onChange={form.handleChange("email")}
            onBlur={form.handleBlur("email")}
            error={form.touched.email ? form.errors.email : undefined}
          />

          <InputField
            name="jobTitle"
            disabled={form.isSubmitting}
            value={form.values.jobTitle}
            label="Job title"
            onChange={form.handleChange("jobTitle")}
            onBlur={form.handleBlur("jobTitle")}
            error={form.touched.jobTitle ? form.errors.jobTitle : undefined}
          />
        </Inputs>
        <Button level="primary" type="submit" disabled={form.isSubmitting}>
          {form.isSubmitting ? "Saving..." : "Save changes"}
        </Button>
      </MainSection>

      <SecondarySection>
        <Spacing size={4}>
          <ProfilePhotoContainer>
            <Spacing size={6}>
              <UploadField
                label="Profile picture"
                value={data.profileImagePath ?? undefined}
                display={src => (
                  <Centered>
                    <CircularImg
                      src={src}
                      width={pxToRem(150)}
                      alt="Your profile picture"
                    />
                  </Centered>
                )}
                accept={["image/png", "image/jpg", "image/jpeg"]}
              />
            </Spacing>
          </ProfilePhotoContainer>

          <Spacing>
            {canChangeCurrentCompany && (
              <Spacing>
                <SelectField
                  inputId="currentCompany"
                  aria-label="Current company"
                  name="currentCompany"
                  isLoading={companiesLoading}
                  styles={{
                    container: provided => ({
                      ...provided,
                      textAlign: "left",
                    }),
                  }}
                  onChange={e => {
                    if (e) {
                      update({
                        variables: { input: { currentCompanyId: e.value } },
                        onCompleted: () => {
                          client.resetStore();
                        },
                      });
                    }
                  }}
                  onBlur={form.handleBlur("currentCompany")}
                  value={companyOptions.find(
                    o => o.value === data?.currentCompany?.id
                  )}
                  options={companyOptions}
                />
                <Toggle
                  checked={sensitiveFieldsHidden}
                  label="Hide sensitive fields"
                  onChange={() => {
                    setSensitiveInfoSetting(!sensitiveFieldsHidden);
                  }}
                />
              </Spacing>
            )}
            <Button
              level="secondary"
              as={Link}
              to="/logout"
              style={{ width: "100%" }}
            >
              Log out
            </Button>

            <Button
              level="destructive"
              as={Link}
              to="../delete-my-account"
              style={{ width: "100%" }}
            >
              Delete account
            </Button>
          </Spacing>
        </Spacing>
      </SecondarySection>
    </OuterContainer>
  );
};
