import styled from "@xstyled/styled-components";

import { Endorsement } from "./Endorsement";

import { CompanyEmployeeEndorsementsFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";

interface EndorsementListProps {
  company: CompanyEmployeeEndorsementsFragment;
}

const Container = styled.div`
  text-align: left;
`;

const EndorsementsWrapper = styled.div`
  > div {
    padding: 20 0 30;
    border-bottom: 2px solid ${palette.brand.grey};
    color: black;
  }

  > :last-child {
    border-bottom: none;
    padding: 20 0 0;
  }
`;

export function EndorsementList({
  company,
}: EndorsementListProps): React.ReactElement {
  return (
    <Container data-testid="endorsement-list-section">
      <Spacing size={-5}>
        <Text size={1} bold>
          All endorsements ({company.employeeSurveys.length})
        </Text>
        <Text color={palette.grayscale.shade600} size={-1}>
          Visible on your company page
        </Text>
      </Spacing>
      <EndorsementsWrapper>
        <Spacing size={-1}>
          {company.employeeSurveys.map((endorsement, index) => (
            <Endorsement key={index} endorsement={endorsement} />
          ))}
        </Spacing>
      </EndorsementsWrapper>
    </Container>
  );
}
