import styled from "@xstyled/styled-components";

import { UploadedFile } from "./types";

import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const PreviewImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(150)};
`;

const ImageContainer = styled.div`
  margin: md;
  display: flex;
  align-items: stretch;
`;

const ImageList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const PreviewContainer = styled.div`
  flex-grow: 1;
`;

const PreviewActionTray = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 6;
`;

const DeleteIcon = styled(Icon).attrs({ icon: "delete", size: 2 })`
  color: red-600;
  &:hover {
    color: red-800;
    cursor: pointer;
  }
`;

export const ImagePreview = ({
  images,
  nounSingular,
  removeFile,
}: {
  images: UploadedFile[];
  nounSingular: string;
  removeFile: (index: number) => void;
}) => {
  return (
    <ImageList>
      {images.map((image, index) => (
        <ImageContainer key={`container-${index}`}>
          <PreviewContainer>
            <PreviewImage src={image.path} alt={nounSingular} />
          </PreviewContainer>
          <PreviewActionTray>
            <DeleteIcon
              aria-label={`Remove ${nounSingular}`}
              onClick={() => removeFile(index)}
            />
          </PreviewActionTray>
        </ImageContainer>
      ))}
    </ImageList>
  );
};
