import styled from "@xstyled/styled-components";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import img from "./assets/sourcing.svg";

import { Button, Card, Heading, Spacing, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Icon } from "@otta/icons";

const Wrapper = styled(Card)`
  display: flex;
  align-items: center;
  padding: 40;
  background-color: yellow-500;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SourcingButton = styled(Button)`
  display: flex;
  justify-content: space-between;
`;

const SearchIcon = styled(Icon)`
  margin-right: sm;
`;

const Image = styled.img`
  height: ${pxToRem(160)};
`;

export const SourcingNudge = ({
  relevantCandidates,
}: {
  relevantCandidates: number;
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed",
      name: "sourcing-nudge",
      page: pathname,
    });
  }, [pathname]);

  return (
    <Wrapper>
      <Content>
        <Spacing>
          <Heading size={2}>
            Source from {relevantCandidates.toLocaleString()} candidates today
          </Heading>
          <Text size={1}>
            Easily filter and engage top candidates who may not have applied
            yet.
            <br />
            Access proprietary insights to understand their intent and
            preferences.
          </Text>
          <SourcingButton
            as={"a"}
            href="sourcing"
            level="secondary"
            data-analytics-id="sourcing-nudge"
          >
            <SearchIcon icon="sourcing" size={1} />
            Go to sourcing
          </SourcingButton>
        </Spacing>
      </Content>
      <Image src={img} alt="Upsell" />
    </Wrapper>
  );
};
