import styled from "@xstyled/styled-components";

export const SourcingSideBar = styled.div`
  padding: lg;
  background-color: white;
  width: 100%;
  max-width: 350px;
  overflow-y: auto;
`;

export const SpinnerWrapper = styled.div`
  padding: 5xl 0;
`;

export const PageContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  height: 100%;
`;

export const CandidatesContainer = styled.div`
  padding: lg;
  flex: 1;
  overflow-y: scroll;
`;
