import styled from "@xstyled/styled-components";

import { SectionProps } from "../../common/types";

import { MaybeProxiedImage } from "@hire/components/images/MaybeProxiedImage";
import { pxToRem } from "@otta/design-tokens";

const Logo = styled(MaybeProxiedImage)`
  max-width: ${pxToRem(180)};
  object-fit: contain;
  max-height: 2rem;
  min-height: 2rem;
`;

const LogoPlaceholder = styled.div`
  width: ${pxToRem(200)};
  height: ${pxToRem(48)};
  background-color: beige-200;
  justify-content: center;
  border-radius: 0.5rem;
  align-items: center;
  display: flex;
`;

export function CompanyLogo({
  company: { logoPath, name },
}: SectionProps): React.ReactElement | null {
  if (!logoPath) {
    return <LogoPlaceholder>[Logo]</LogoPlaceholder>;
  } else {
    return <Logo width={400} path={logoPath} alt={name} />;
  }
}
