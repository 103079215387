import styled, { keyframes } from "@xstyled/styled-components";
import { useMemo } from "react";

import {
  BarHeader,
  CHART_COLORS,
  GraphHeader,
  UNKNOWN_COLOR,
} from "./graph-helpers";
import { FilterLegend } from "./FilterLegend";

import { palette, pxToRem } from "@otta/design-tokens";
import { ANALYTICS_DESKTOP_BREAKPOINT } from "@hire/components/layout/constants";

const GraphCard = styled.div`
  background-color: white;
  border: 1px solid ${palette.grayscale.shade200};
  border-radius: ${pxToRem(8)};
  padding: lg;
  justify-content: center;
  align-items: center;
  min-height: ${pxToRem(267)};
  height: 100%;
`;

const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${pxToRem(250)};
  width: 100%;
`;

const Graph = styled.div`
  width: 100%;
  border-bottom: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  border-left: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  margin-bottom: 50;
  height: ${pxToRem(160)};
  display: flex;
  justify-content: space-between;
  padding: 0 40;

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    padding: 0 92;
  }
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  position: relative;
  min-width: ${pxToRem(64)};
`;

const barAnimation = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: var(--barHeight);
  }
`;

const Bar = styled.div`
  width: ${pxToRem(64)};
  height: var(--barHeight);
  border-radius: ${pxToRem(8)} ${pxToRem(8)} 0 0;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  will-change: height;
  animation: ${barAnimation} 0.4s;
`;

const BarSection = styled.div<{
  height: number;
  color: string;
}>`
  background-color: ${({ color }) => color};
  width: 100%;
  height: ${({ height }) => `${height}%`};
  min-height: ${pxToRem(2)};
  transition: default;
  transition-property: height, background-color;
  &:not(:first-child) {
    border-top: ${pxToRem(2)} solid white;
  }

  &[data-hovered="false"] {
    opacity: 0.3;
    transition: default;
  }
`;

type BarDetails = {
  title: string;
  values: {
    total: number;
    value: string;
    percent: string;
  }[];
};

export function StackedBarGraph({
  bars,
  graphTitle,
  hoveredItem,
  setHoveredItem,
}: {
  bars: BarDetails[];
  graphTitle: string;
  hoveredItem: string | undefined;
  setHoveredItem: (value: string) => void;
}) {
  const totalColumn = (column: BarDetails["values"]) =>
    column.reduce((acc, val) => acc + val.total, 0);

  const sectionsWithData = useMemo(() => {
    const legend = new Set<string>();
    for (const bar of bars) {
      for (const section of bar.values) {
        if (parseInt(section.percent) !== 0) {
          legend.add(section.value);
        }
      }
    }

    return legend;
  }, [bars]);

  const maxDataPoint = useMemo(
    () => Math.max(...bars.map(bar => totalColumn(bar.values))),
    [bars]
  );

  const barHeight = (value: number) => {
    return (value / maxDataPoint) * 100;
  };

  return (
    <GraphCard data-testid="stacked-bar-graph">
      <GraphContainer>
        <GraphHeader bold>{graphTitle}</GraphHeader>
        <Graph>
          {bars.map(column => (
            <Column key={column.title}>
              <Bar
                style={
                  {
                    "--barHeight": `${barHeight(totalColumn(column.values))}%`,
                  } as React.CSSProperties
                }
              >
                {column.values.map(
                  (section, index) =>
                    parseInt(section.percent) !== 0 && (
                      <BarSection
                        key={index}
                        data-testid={`bar-${section.value}`}
                        height={
                          parseInt(section.percent) > 10
                            ? parseInt(section.percent)
                            : 10
                        }
                        color={
                          section.value !== "Unknown"
                            ? CHART_COLORS[index]
                            : UNKNOWN_COLOR
                        }
                        data-hovered={
                          !hoveredItem ? null : hoveredItem === section.value
                        }
                        onMouseEnter={() => setHoveredItem?.(section.value)}
                        onMouseLeave={() => setHoveredItem?.("")}
                      />
                    )
                )}
              </Bar>
              <BarHeader bold>{column.title}</BarHeader>
            </Column>
          ))}
        </Graph>
      </GraphContainer>
      <FilterLegend
        chartData={bars[0].values}
        sectionsWithData={sectionsWithData}
        hoveredItem={hoveredItem}
        setHoveredItem={setHoveredItem}
      />
    </GraphCard>
  );
}
