import { useState } from "react";
import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Button, Modal, Text, VerticalSpacing } from "@otta/design";

const BinContainer = styled.div`
  display: flex;
  align-items: start;
  position: relative;

  top: -${pxToRem(1)};
  height: ${pxToRem(22)};
  width: ${pxToRem(20)};

  color: gray-400;

  :hover {
    cursor: pointer;
    color: gray-600;
    transition: default;
  }
`;

const WideButton = styled(Button)`
  width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: xl;
`;

interface DeleteStageProps {
  isEmpty: boolean;
  handleDelete: () => void;
  stageName: string;
}

export const DeleteStage = ({
  handleDelete,
  stageName,
  isEmpty,
}: DeleteStageProps): React.ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <BinContainer
        aria-label={`Delete ${stageName}`}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <Icon icon="delete" color={palette.grayscale.shade400} size={2} />
      </BinContainer>

      <Modal
        open={modalOpen}
        onOpenChange={() => {
          setModalOpen(!modalOpen);
        }}
      >
        <ModalContent>
          <VerticalSpacing size={1}>
            <Text color={palette.brand.red} size={1} bold align="center">
              Delete stage
            </Text>
            {!isEmpty ? (
              <>
                <Text size={1} align="center">
                  Move all candidates from &ldquo;
                  <strong>{stageName}</strong>&rdquo; before deleting this
                  stage.
                </Text>
                <WideButton
                  level="primary"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  Close
                </WideButton>
              </>
            ) : (
              <>
                <Text size={1} align="center">
                  <Text as="span" bold>
                    This action is irreversible.
                  </Text>{" "}
                  It is not possible to recover a stage once deleted.
                </Text>
                <WideButton
                  level="destructive"
                  onClick={() => {
                    handleDelete();
                    setModalOpen(false);
                  }}
                >
                  Delete &ldquo;{stageName}&rdquo;
                </WideButton>
              </>
            )}
          </VerticalSpacing>
        </ModalContent>
      </Modal>
    </>
  );
};
