export const Experiment = {
  AshbyIntegration: "20230704_ASHBY_ATS_INTEGRATION",
  BooleanSearch: "20240626_HIRE_APP_BOOLEAN_SEARCH",
  NewJobWizard: "20231016_HIRE_APP_NEW_JOB_WIZARD",
  CandidateCount: "20231113_HIRE_APP_CANDIDATE_COUNT",
  NewJobAnalytics: "20240214_NEW_JOB_ANALYTICS",
  OttaWttjRebranding: "20240715_OTTA_WTTJ_REBRANDING",
  OttaCertified: "20240711_OTTA_CERTIFIED",
  NewFont: "20240812_Atkinson",
} as const;
