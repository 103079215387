import styled, { css, up } from "@xstyled/styled-components";

import { Measure } from "./Measure";

import { DashboardFragment } from "@hire/schema";
import { Link } from "@hire/components/links/Link";
import { Salary, Messages } from "@hire/components/icons";
import { pxToRem } from "@otta/design-tokens";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: lg;
  justify-content: center;

  ${up(
    "tablet",
    css`
      grid-template-columns: repeat(auto-fit, minmax(${pxToRem(250)}, 1fr));
    `
  )}
`;

interface CandidateExperienceMeasuresProps {
  currentCompany: DashboardFragment;
  showModal: () => void;
}

interface Status {
  quadrant: 0 | 1 | 2 | 3 | 4;
  description: string;
  tip: string;
}

const getSalaryStatus = ({
  numberLiveJobs,
  numberLiveJobsWithSalary,
  numberLiveJobsWithPublicSalary,
}: {
  numberLiveJobs: number;
  numberLiveJobsWithSalary: number;
  numberLiveJobsWithPublicSalary: number;
}): Status => {
  if (numberLiveJobs === 0) {
    return {
      quadrant: 0,
      description: "No published jobs",
      tip: "We'll let you know if you need to add salaries.",
    };
  } else if (numberLiveJobsWithSalary === 0) {
    return {
      quadrant: 1,
      description: "None of your jobs have salaries",
      tip: "Public salaries are twice as effective as hidden salaries.",
    };
  } else if (numberLiveJobsWithSalary !== numberLiveJobs) {
    return {
      quadrant: 2,
      description: "Some of your jobs have salaries",
      tip: "Public salaries are twice as effective as hidden salaries.",
    };
  } else if (numberLiveJobsWithSalary !== numberLiveJobsWithPublicSalary) {
    return {
      quadrant: 3,
      description: "Make all of your salaries public",
      tip: "Public salaries are twice as effective as hidden salaries.",
    };
  } else {
    return {
      quadrant: 4,
      description: "All your jobs have public salaries",
      tip: "We'll let you know if you need to add salaries.",
    };
  }
};

const getResponseRateStatus = (responseRate: string | null): Status => {
  if (responseRate === null) {
    return {
      quadrant: 0,
      description: "Awaiting data",
      tip: "Your response rate is displayed to candidates on your job cards.",
    };
  }

  const rate = Math.round(parseFloat(responseRate) * 100);
  if (rate >= 80) {
    return {
      quadrant: 4,
      description: "Most candidates hear back",
      tip: "Keep responding to candidates to maintain your response rate.",
    };
  } else if (rate >= 60) {
    return {
      quadrant: 3,
      description: "Many candidates hear back",
      tip: "Respond to candidates within two weeks to increase your response rate.",
    };
  } else if (rate >= 30) {
    return {
      quadrant: 2,
      description: "Some candidates hear back",
      tip: "Respond to candidates within two weeks to increase your response rate.",
    };
  } else {
    return {
      quadrant: 1,
      description: "Few candidates hear back",
      tip: "Respond to candidates within two weeks to increase your response rate.",
    };
  }
};

export const CertifiedOverview = ({
  currentCompany,
  showModal,
}: CandidateExperienceMeasuresProps): React.ReactElement => {
  const { responseRate, jobsWithSalaryStats } = currentCompany;

  const salaryStatus = getSalaryStatus(jobsWithSalaryStats);
  const responseRateStatus = getResponseRateStatus(responseRate);

  return (
    <GridContainer>
      <Link
        to="./jobs"
        underline={false}
        data-analytics-id="dashboard-jobs-with-salaries"
      >
        <Measure
          title="Jobs with salaries"
          description={salaryStatus.description}
          tip={salaryStatus.tip}
          fillQuandrant={salaryStatus.quadrant}
          icon={Salary}
          dataTestId="jobs-with-salaries"
        />
      </Link>
      <Measure
        title="Two week response rate"
        description={responseRateStatus.description}
        tip={responseRateStatus.tip}
        fillQuandrant={responseRateStatus.quadrant}
        icon={Messages}
        dataAnalyticsId="dashboard-application-response-rate"
        dataTestId="application-response-rate"
        onClick={showModal}
      />
    </GridContainer>
  );
};
