import { formatDistanceToNowStrict, parseJSON } from "date-fns";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "@xstyled/styled-components";

import {
  modularScale,
  palette,
  pxToRem,
  borderRadiusSmall,
} from "@otta/design-tokens";
import { Card, Text } from "@otta/design";
import { RectangleTag } from "@hire/components/tags/RectangleTag";
import { JobFeature } from "@hire/schema";

const TitleContainer = styled.div`
  flex-wrap: wrap;
  gap: sm;
`;

const Title = styled(Text)`
  font-weight: bold;
`;

const Subtitle = styled(Text)`
  font-size: ${modularScale(-1)};
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Tags = styled.div`
  display: flex;
  gap: sm;
  align-items: flex-start;
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  flex: 1;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: lg;
  padding: lg;
  background-color: white;
  border: 1px solid ${palette.grayscale.shade400};
  border-radius: ${pxToRem(borderRadiusSmall)};
  &:hover {
    background-color: gray-50;
    border: 1px solid ${palette.brand.black};
  }
`;

export const JobCard = ({
  externalId,
  title,
  subtitle,
  postedAt,
  applications,
  unread,
  hasAccess,
}: {
  externalId: string;
  title: string;
  subtitle: string | null;
  postedAt: string | null;
  applications: number;
  unread: number;
  hasAccess: {
    feature: JobFeature;
    granted: boolean;
    message: string | null;
  }[];
}) => {
  const postedAgo = useMemo(() => {
    if (!postedAt) {
      return null;
    }
    const date = parseJSON(postedAt);
    return formatDistanceToNowStrict(date, { addSuffix: true });
  }, [postedAt]);

  const hasPipeline = useMemo(
    () => !!hasAccess.find(a => a.feature === JobFeature.Pipeline)?.granted,
    [hasAccess]
  );

  return (
    <StyledLink data-testid="job-preview" to={`jobs/${externalId}`}>
      <StyledCard>
        <TitleContainer>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleContainer>
        <Tags>
          {hasPipeline && applications > 0 && (
            <RectangleTag
              data-testid="job-preview-applications"
              color={palette.brand.yellow}
              icon="apply"
              content={`${applications} new application${
                applications > 1 ? "s" : ""
              }`}
            />
          )}
          {unread > 0 && (
            <RectangleTag
              color={palette.brand.green}
              icon="response-rate"
              content={`${unread} unread message${unread > 1 ? "s" : ""}`}
            />
          )}
        </Tags>
        {postedAgo && <Text>Posted {postedAgo}</Text>}
      </StyledCard>
    </StyledLink>
  );
};
