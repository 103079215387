import { InputHTMLAttributes } from "react";
import styled from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";
import { Text } from "@otta/design";

const Wrapper = styled.label<{
  disabled: boolean;
}>`
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 8px 0;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  font-size: 16px;
  user-select: none;
  color: black;

  input ~ span {
    background-color: gray-200;
  }

  input:checked ~ span {
    background-color: ${({ disabled }) =>
      disabled ? palette.grayscale.shade200 : palette.brand.yellow};
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 7px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid ${palette.brand.black};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  outline: 1px solid black;
`;

const Checkmark = styled.span<{ disabled: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  height: ${modularScale(1)};
  width: ${modularScale(1)};
  border-radius: 4px;

  background-color: ${({ disabled }) =>
    disabled ? palette.brand.black : palette.brand.grey};

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export function MicroCheckbox({
  label,
  ...props
}: {
  label: string;
} & InputHTMLAttributes<HTMLInputElement>): React.ReactElement {
  return (
    <Wrapper disabled={props.disabled ?? false}>
      <Text size={-1} align="left">
        {label}
      </Text>
      <Input type="checkbox" {...props} />
      <Checkmark disabled={props.disabled ?? false} />
    </Wrapper>
  );
}
