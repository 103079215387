import { format, parseISO } from "date-fns";
import styled from "@xstyled/styled-components";

import { Checkbox } from "../../Checkbox";

import { ActionApplicationBadge, UnreadMessageBadge } from "./ActionBadges";

import { StageType } from "@hire/schema";
import { OttaCandidatePipelineItem } from "@hire/pages/JobPage/Pipeline/types";
import { modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text, VerticalSpacing } from "@otta/design";

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  display: flex;
  color: gray-600;
`;

const TimeStampText = styled(Text)`
  display: flex;
  align-items: center;
  color: gray-600;
  white-space: nowrap;

  i {
    font-size: ${modularScale()};
    margin-right: 3;
  }
`;

export const OttaCandidate = ({
  item,
  stageType,
  isSelected,
  selectionModeEnabled,
  toggleItemSelection,
}: {
  item: OttaCandidatePipelineItem;
  stageType: StageType;
  isSelected: boolean;
  selectionModeEnabled: boolean;
  toggleItemSelection: (itemIds: string[]) => void;
}): React.ReactElement => {
  const appliedAt = item.jobApplication?.appliedAt;
  const companyName = item.candidate.workExperience?.[0]?.companyName;
  const externalId = item.candidate.externalId;
  const hasNote = item.candidatePipelineItemNote !== null;
  const hasUnactionedApplication = !!(
    item.jobApplication &&
    item.jobApplication.accepted === null &&
    !item.latestMessage &&
    stageType != StageType.Hired
  );
  const hasUnreadMessage = !!(
    !hasUnactionedApplication &&
    item.latestMessage &&
    item.latestMessage.sender.id === item.candidate.id &&
    !item.latestMessage?.read
  );
  const showCheckbox =
    (selectionModeEnabled || isSelected) &&
    !(item.messageRequest && item.messageRequest?.accepted !== true);
  const timeStamp =
    item.latestMessage?.insertedAt ?? item.jobApplication?.appliedAt;
  const title = `${item.candidate.firstName} ${item.candidate.lastName}`;
  const isArchivedStage = stageType === StageType.Archived;

  const handleChange = () => {
    toggleItemSelection([item.id]);
  };

  return (
    <VerticalSpacing size={-4}>
      <TitleContainer>
        <Text bold>{title}</Text>
        {!isArchivedStage && (
          <Checkbox
            show={showCheckbox}
            checked={isSelected}
            handleChange={handleChange}
            data-analytics-id="pipeline-item-checkbox"
          />
        )}
      </TitleContainer>
      <Text>{companyName}</Text>
      {hasUnactionedApplication && appliedAt && (
        <ActionApplicationBadge appliedAt={appliedAt} />
      )}
      {hasUnreadMessage && <UnreadMessageBadge externalId={externalId} />}
      <Footer>
        {timeStamp && (
          <TimeStampText size={-1}>
            <Icon icon="response-rate" />
            {format(parseISO(timeStamp), "d MMM yy")}
          </TimeStampText>
        )}
        {hasNote && (
          <IconWrapper>
            <Icon icon="document" />
          </IconWrapper>
        )}
      </Footer>
    </VerticalSpacing>
  );
};
