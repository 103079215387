import styled from "@xstyled/styled-components";
import { useMemo } from "react";

import { Section, SectionHeader } from "../../common/Section";
import { SectionProps } from "../../common/types";
import { CardText } from "../../common/CardText";

import { useCompanyIsActive } from "@hire/hooks/useCompanyIsActive";
import { Spacing, Text, Logo as OttaLogo, WttjSymbol } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import Sam from "@hire/images/Headshots/Sam.jpg";
import Theo from "@hire/images/Headshots/Theo.jpeg";
import Xav from "@hire/images/Headshots/Xav.jpg";
import { useHasNewLogo } from "@hire/components/Logo";

const YellowHeader = styled(SectionHeader)`
  background-color: yellow-500;
  display: flex;
`;

const StyledOttaLogo = styled(OttaLogo)`
  height: ${pxToRem(20)};
  align-self: center;
  margin-left: auto;
`;

const StyledWttjSymbol = styled(WttjSymbol)`
  height: ${pxToRem(32)};
  align-self: center;
  margin-left: auto;
`;

const xav = {
  name: "Xav Kearney",
  title: "CTO of Otta",
  image: Xav,
};

const theo = {
  name: "Theo Margolius",
  title: "COO of Otta",
  image: Theo,
};

const sam = {
  name: "Sam Franklin",
  title: "CEO of Otta",
  image: Sam,
};

const people = [xav, theo, sam];

const Headshot = styled.img`
  border-radius: ${pxToRem(56)};
  width: ${pxToRem(56)};
`;

const AuthorSection = styled.div`
  flex-direction: row;
  display: flex;
`;

const AuthorName = styled.div`
  justify-content: center;
  flex-direction: column;
  margin-left: 0.5rem;
  display: flex;
  gap: 0.15rem;
`;

export function OttasTake({
  company: { marketBullets, id },
}: SectionProps): React.ReactElement | null {
  const author = useMemo(() => people[parseInt(id) % people.length], [id]);

  const { active } = useCompanyIsActive();
  const hasNewLogo = useHasNewLogo();

  return active && marketBullets.length > 0 ? (
    <Section>
      <YellowHeader>
        Our Take {hasNewLogo ? <StyledWttjSymbol /> : <StyledOttaLogo />}
      </YellowHeader>
      <Spacing size={0}>
        <AuthorSection>
          <Headshot src={author.image} />
          <AuthorName>
            <Text bold>{author.name}</Text>
            <Text>{author.title}</Text>
          </AuthorName>
        </AuthorSection>
        {marketBullets.map(({ value }, index) => (
          <CardText key={index}>{value}</CardText>
        ))}
      </Spacing>
    </Section>
  ) : null;
}
