import { useCallback, useMemo } from "react";
import { FormikErrors, useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { FormFields } from "./types";
import { transformIn, transformOut } from "./transformers";
import { BenefitsBullets } from "./BenefitsBullets.tsx";

import { CompanyBenefitsFragment, CompanyInput } from "@hire/schema";
import { Button, Spacing, Text } from "@otta/design";
import { mustNotContainEmail } from "@hire/validation/strings";

const HeadingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 19;
`;

const GuidanceText = styled(Text)`
  text-align: left;
  color: gray-600;
`;

const validationSchema = Yup.object().shape({
  otherBenefits: Yup.array().of(
    Yup.object().shape({
      location: Yup.string().optional(),
      value: Yup.string().test(mustNotContainEmail).optional().label("Benefit"),
    })
  ),
});

export const CompanyBenefitsForm = ({
  data,
  handleSave,
}: {
  data: CompanyBenefitsFragment;
  handleSave: (input: CompanyInput) => void;
}) => {
  const initialValues = useMemo(() => transformIn(data), [data]);

  const onSubmit = useCallback(
    (values: FormFields) => {
      return handleSave(transformOut(values));
    },
    [handleSave]
  );

  const form = useFormik<FormFields>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Spacing size={0}>
        <HeadingsWrapper>
          <Text bold align="left">
            Benefits provided
          </Text>
          <Text bold align="right">
            Location
          </Text>
        </HeadingsWrapper>
        <GuidanceText>
          E.g. Paid time off policies, wellbeing initiatives, insurance
          packages, retirement plans and training budgets.
        </GuidanceText>
        <BenefitsBullets
          fieldName="otherBenefits"
          values={form.values.otherBenefits}
          onChange={b => form.setFieldValue("otherBenefits", b)}
          onBlur={form.handleBlur("otherBenefits")}
          errors={
            form.touched.otherBenefits &&
            // Formik types all errors as strings even for nested values
            (
              (form.errors.otherBenefits as FormikErrors<
                FormFields["otherBenefits"]
              >) ?? []
            ).map(e => e && e.value)
          }
        />

        <Button type="submit" level="primary" disabled={form.isSubmitting}>
          {form.isSubmitting ? "Saving..." : "Save changes"}
        </Button>
      </Spacing>
    </form>
  );
};
