import { addDays, differenceInDays, format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import styled, { css } from "@xstyled/styled-components";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";

const NotificationWrapper = styled.div`
  display: inline-flex;
  background-color: green-200;
  border-radius: ${pxToRem(4)};
  padding: xs xs;
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  i {
    font-size: ${modularScale()};
    margin-right: xs;
    padding-bottom: xxs;
  }
`;

const Badge = styled.div<{ $colour: keyof typeof palette.extended }>`
  ${({ $colour }) =>
    css`
      background-color: ${palette.extended[$colour].shade200};
    `}
  border-radius: ${pxToRem(4)};
  padding: xxs xs;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
`;

const Dot = styled.span`
  background-color: red-600;
  color: white;
  border-radius: 50%;
  width: ${pxToRem(8)};
  height: ${pxToRem(8)}};
  margin-right: sm;
`;

export const ActionApplicationBadge = ({
  appliedAt,
}: {
  appliedAt: string;
}): React.ReactElement => {
  const replyByDay = format(addDays(parseISO(appliedAt), 14), "dd MMMM");
  const daysPassed = differenceInDays(new Date(), parseISO(appliedAt));

  const colour = daysPassed < 7 ? "yellow" : daysPassed < 14 ? "orange" : "red";

  const message =
    daysPassed < 14
      ? `Reply by ${replyByDay}`
      : `Applied ${daysPassed} days ago`;

  return (
    <Badge data-testid="action-application-badge" $colour={colour}>
      <Dot />
      <Text bold size={-1}>
        {message}
      </Text>
    </Badge>
  );
};

export const UnreadMessageBadge = ({
  externalId,
}: {
  externalId: string;
}): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <NotificationWrapper
      onClick={e => {
        e.stopPropagation();
        navigate(
          `candidate/${externalId}/conversation"
          }`
        );
      }}
    >
      <StyledText bold size={-1}>
        <Icon icon="response-rate" />
        New message
      </StyledText>
    </NotificationWrapper>
  );
};
