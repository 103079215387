import { Divider } from "../../common/Divider";
import { IconList, IconListItem } from "../../common/IconList";
import { SectionProps } from "../../common/types";
import { Section } from "../../common/Section";

import { CompanyState } from "./CompanyState";
import { CompanySubtitle } from "./CompanySubtitle";
import { CompanyLogo } from "./CompanyLogo";
import { JobTitle } from "./JobTitle";
import { JobSalary } from "./JobSalary";
import { OtherCompensation } from "./OtherCompensation";
import { JobLevel } from "./JobLevel";
import { JobLocations } from "./JobLocations";
import { JobHybridInfo } from "./JobHybridInfo";
import { OfficeLocation } from "./OfficeLocation";
import { CompanySize } from "./CompanySize";
import { CompanySectorTags } from "./CompanySectorTags";
import { CompanyMission } from "./CompanyMission";
import { JobTechnologies } from "./JobTechnologies";

import { Spacing } from "@otta/design";

export function Header(props: SectionProps): React.ReactElement {
  return (
    <Section>
      <Spacing size={0}>
        <JobTitle {...props} />
        <CompanyLogo {...props} />
        <CompanySubtitle {...props} />
        <CompanyState />
        <Divider />
        <IconList>
          <IconListItem icon="salary">
            <JobSalary {...props} />
          </IconListItem>
          <IconListItem>
            <OtherCompensation {...props} />
          </IconListItem>
          <IconListItem icon="technology">
            <JobTechnologies {...props} />
          </IconListItem>
          <IconListItem icon="steps">
            <JobLevel {...props} />
          </IconListItem>
          <IconListItem icon="location">
            <JobLocations {...props} />
          </IconListItem>
          <IconListItem>
            <JobHybridInfo {...props} />
          </IconListItem>
          <IconListItem icon="company">
            <OfficeLocation {...props} />
          </IconListItem>
        </IconList>
        <Divider />
        <IconList>
          <IconListItem icon="users">
            <CompanySize {...props} />
          </IconListItem>
          <IconListItem icon="industry">
            <CompanySectorTags {...props} />
          </IconListItem>
        </IconList>
        <Divider />
        <CompanyMission {...props} />
      </Spacing>
    </Section>
  );
}
