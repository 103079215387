import styled from "@xstyled/styled-components";
import { useState } from "react";

import { SSOMetadataForm } from "./SSOMetadataForm";

import { CompanySamlData } from "@hire/schema";
import { Button, Spacing, Card, Text } from "@otta/design";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: md;
`;

const ConfigText = styled(Text)`
  color: gray-600;
  overflow-wrap: break-word;
`;

interface SSOMetadataFormProps {
  samlData: CompanySamlData;
}

export function SSOMetadata({
  samlData: {
    consumeUrl,
    entityId,
    idpMetadata,
    enforced,
    idpLoginUrl,
    idpEntityId,
    hasIdpCerts,
  },
}: SSOMetadataFormProps): React.ReactElement {
  const [editing, setEditing] = useState(!idpMetadata);

  return (
    <Card>
      {editing ? (
        <SSOMetadataForm
          consumeUrl={consumeUrl}
          entityId={entityId}
          enforced={enforced}
          idpMetadata={idpMetadata}
          onClose={() => setEditing(false)}
        />
      ) : (
        <SSOMetadataDisplay
          idpEntityId={idpEntityId}
          idpLoginUrl={idpLoginUrl}
          hasIdpCerts={hasIdpCerts}
          onClose={() => setEditing(true)}
        />
      )}
    </Card>
  );
}

function SSOMetadataDisplay({
  idpEntityId,
  idpLoginUrl,
  hasIdpCerts,
  onClose,
}: {
  idpEntityId: string | null;
  idpLoginUrl: string | null;
  hasIdpCerts: boolean;
  onClose: () => void;
}) {
  return (
    <Spacing>
      <Text bold>Your IdP Configuration</Text>
      {idpEntityId && (
        <Spacing size={-4}>
          <Text bold>Entity ID</Text>
          <ConfigText>{idpEntityId}</ConfigText>
        </Spacing>
      )}
      {idpLoginUrl && (
        <Spacing size={-4}>
          <Text bold>SSO Service POST URL</Text>
          <ConfigText>{idpLoginUrl}</ConfigText>
        </Spacing>
      )}
      {hasIdpCerts && <Text bold>X509 certificate successfully uploaded</Text>}
      <ButtonContainer>
        <Button level="secondary" type="button" onClick={onClose}>
          Edit
        </Button>
      </ButtonContainer>
    </Spacing>
  );
}
