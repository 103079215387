import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, ToastContainerProps } from "react-toastify";
import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { responsive } from "@otta/design";

const WrappedToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => (
  <div className={className} style={{ width: "100%" }}>
    <ToastContainer
      position="bottom-center"
      autoClose={4000}
      hideProgressBar={true}
      {...rest}
    />
  </div>
);

export const Toast = styled(WrappedToastContainer)`
  .Toastify__toast-container {
    width: fit-content;
    max-width: 500px;
    text-align: center;
    padding: md;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .Toastify__toast {
    min-height: unset;
    font-family: unset;
    color: white;
    border-radius: ${pxToRem(5)};
    ${responsive`
      font-size: ${responsive.modularScale({ mobile: -1, desktop: 0 })};
    `}
  }
  .Toastify__toast--error {
    background-color: red-600;
  }
  .Toastify__toast--warning {
    background-color: orange-500;
  }
  .Toastify__toast--success {
    background-color: black;
  }
  .Toastify__toast--info {
    background-color: blue-400;
  }
  .Toastify__close-button {
    display: none;
  }
`;
