/* eslint-disable react/jsx-no-useless-fragment */
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";

import { BroadcastForm } from "./BroadcastForm";
import { NotEnoughCandidates } from "./components/NotEnoughCandidates";
import { PreviewJobBroadcast } from "./PreviewBroadcast";
import { JobBroadcastAlreadySent } from "./JobBroadcastAlreadySent";
import { JobBroadcastSent } from "./JobBroadcastSent";

import {
  CreateJobBroadcastDocument,
  Job,
  User,
  Company as CurrentCompany,
  JobBroadcastDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";

type BroadcastState = "EDIT" | "PREVIEW" | "COMPLETE";

export function BroadcastOverview({
  jobId,
}: {
  jobId: string;
}): React.ReactElement {
  const [message, setMessage] = useState("");
  const [broadcastState, setBroadcastState] = useState<BroadcastState>("EDIT");
  const [error, setError] = useState<ApolloError>();
  const [totalRecipients, setTotalRecipients] = useState<number>();
  const [currentNumberRecipients, setCurrentNumberRecipients] =
    useState<number>();

  const {
    data,
    loading: loadingQuery,
    refetch,
  } = useQuery(JobBroadcastDocument, {
    variables: {
      jobId: jobId,
    },
  });

  const currentUser = data?.currentUser as User;
  const companyJob = data?.companyJob as Job;
  const numberBroadcastableCandidates =
    companyJob?.numberBroadcastableCandidates;

  const totalPreviousBroadcastRecipients =
    companyJob?.numberBroadcastRecipients;

  const previousBroadcast = companyJob?.broadcasts?.slice(-1)[0];
  const company = currentUser?.currentCompany as CurrentCompany;

  const handleError = (error: ApolloError) => {
    setError(error);
  };

  const [createJobBroadcast, { loading: loadingMutation }] = useMutation(
    CreateJobBroadcastDocument,
    {
      variables: { message: message, jobId: companyJob?.id },
      onError: handleError,
      onCompleted: () => {
        setTotalRecipients(
          (numberBroadcastableCandidates ?? 0) +
            (totalPreviousBroadcastRecipients ?? 0)
        );
        setCurrentNumberRecipients(numberBroadcastableCandidates ?? 0);
        setBroadcastState("COMPLETE");
      },
    }
  );

  if (loadingQuery || loadingMutation) {
    return <Loading />;
  }

  const handleSubmitForPreview = (input: string) => {
    setMessage(input);
    setBroadcastState("PREVIEW");
  };

  const handleSubmit = () => {
    createJobBroadcast();
    setBroadcastState("COMPLETE");
  };

  if (!company || !currentUser) {
    return <ReloadableError action={() => refetch()} />;
  }
  if (numberBroadcastableCandidates === 0) {
    return <NotEnoughCandidates previousBroadcast={previousBroadcast} />;
  }

  return (
    <>
      {error ? (
        <JobBroadcastAlreadySent />
      ) : broadcastState === "EDIT" ? (
        <BroadcastForm
          message={message}
          profileImagePath={currentUser?.profileImagePath}
          handleSave={handleSubmitForPreview}
          numberOfCandidates={numberBroadcastableCandidates}
          companyName={company.name}
        />
      ) : broadcastState === "PREVIEW" ? (
        <PreviewJobBroadcast
          numberCandidates={numberBroadcastableCandidates}
          job={companyJob}
          company={company}
          recruiter={currentUser}
          onCancelPreview={() => {
            setBroadcastState("EDIT");
          }}
          onSubmit={handleSubmit}
          submitLoading={loadingQuery || loadingMutation}
          message={message}
        />
      ) : broadcastState === "COMPLETE" ? (
        <JobBroadcastSent
          numberCandidates={currentNumberRecipients}
          totalPreviousBroadcastRecipients={totalRecipients}
        />
      ) : (
        <ReloadableError action={() => refetch()} />
      )}
    </>
  );
}
