import styled from "@xstyled/styled-components";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import img from "./assets/upsell.svg";

import { Button, Heading, Spacing, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Icon } from "@otta/icons";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  gap: xl;
  padding: 40;
  border-radius: ${pxToRem(16)};
  background-color: yellow-500;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: sm;
  list-style: none;
  margin: lg 0 xl;
`;

const ListItem = styled.li`
  display: flex;
  gap: sm;
`;

export const Upsell = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed",
      name: "sourcing-upsell",
      page: pathname,
    });
  }, [pathname]);

  return (
    <Spacing size={2}>
      <Text bold size={2}>
        Sourcing performance
      </Text>
      <Wrapper>
        <Content>
          <Heading size={2}>Unlock more features with Pro</Heading>
          <List>
            <ListItem>
              <Icon icon="checkmark" size={2} />
              <Text size={1}>3x more views of all your jobs with Boost</Text>
            </ListItem>
            <ListItem>
              <Icon icon="checkmark" size={2} />
              <Text size={1}>
                Find and message talent who may not have applied yet with
                sourcing
              </Text>
            </ListItem>
            <ListItem>
              <Icon icon="checkmark" size={2} />
              <Text size={1}>
                Filter talent and performance data by gender, ethnicity and
                seniority
              </Text>
            </ListItem>
            <ListItem>
              <Icon icon="checkmark" size={2} />
              <Text size={1}>
                Benchmark performance against similar companies
              </Text>
            </ListItem>
          </List>
          <Button
            level="secondary"
            as="a"
            data-analytics-id="book-a-demo"
            href="/book-a-call?utm_source=otta&utm_medium=jobs&utm_campaign=page_analytics&utm_id=hire_app&utm_term=book_a_demo&utm_content="
            target="_blank"
            rel="noopener noreferrer"
          >
            Book a demo
          </Button>
        </Content>
        <img src={img} alt="Upsell" />
      </Wrapper>
    </Spacing>
  );
};
