import styled, { css, down } from "@xstyled/styled-components";
import { format } from "date-fns";

import { modularScale } from "@otta/design-tokens";
import { Heading, Spacing, Tipbox, Text } from "@otta/design";
import { pluraliser } from "@hire/util/strings";

interface UserProps {
  firstName: string;
  lastName: string;
}
interface PreviousBroadcastProps {
  companyRecruiter: UserProps;
  numberBroadcastedTo: number;
  insertedAt: string;
}

interface NotEnoughCandidatesProps {
  previousBroadcast?: PreviousBroadcastProps;
}

const HeadingWrapper = styled.div`
  display: flex;
  gap: 10;
  align-items: center;
`;

const StyledTipbox = styled(Tipbox)`
  ${down(
    "tablet",
    css`
      padding-right: 28;
    `
  )};
  background-color: white;
  border-radius: ${modularScale(1)};
  padding-top: 19;
  padding-bottom: 19;
  text-align: left;
`;

export function NotEnoughCandidates({
  previousBroadcast,
}: NotEnoughCandidatesProps): React.ReactElement {
  return (
    <StyledTipbox level="information">
      <Spacing>
        <HeadingWrapper>
          <Heading>No candidates available for broadcast</Heading>
        </HeadingWrapper>
        {previousBroadcast ? (
          <Text data-cs-mask>
            {previousBroadcast.companyRecruiter.firstName}{" "}
            {previousBroadcast.companyRecruiter.lastName} sent a broadcast to{" "}
            {previousBroadcast.numberBroadcastedTo}{" "}
            {pluraliser(previousBroadcast.numberBroadcastedTo, "candidate")} on{" "}
            {format(new Date(previousBroadcast.insertedAt), "d MMMM Y")}. You
            can send another one when new candidates save the role.
          </Text>
        ) : (
          <Text>You can send a broadcast when candidates save the role.</Text>
        )}
      </Spacing>
    </StyledTipbox>
  );
}
