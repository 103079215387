import { CardText } from "../../common/CardText";
import { Divider } from "../../common/Divider";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { Link } from "@hire/components/links/Link";
import { Spacing, Text } from "@otta/design";

type Person = {
  id: string;
  name: string;
  linkedinUrl: string | null;
  personTitle?: string;
  bio: string | null;
};

function Profile(person: Person) {
  return (
    <Spacing size={0}>
      <Text key={person.id}>
        {person.linkedinUrl ? (
          <Link style={{ fontWeight: "normal" }} to={person.linkedinUrl} newTab>
            {person.name}
          </Link>
        ) : (
          person.name
        )}{" "}
        {!!person.personTitle && `(${person.personTitle})`}
      </Text>
      <CardText>{person.bio}</CardText>
    </Spacing>
  );
}

function Profiles({
  title,
  profiles,
}: {
  title: string;
  profiles: Person[];
}): React.ReactElement | null {
  return profiles.length > 0 ? (
    <Spacing size={0}>
      <Divider />
      <Subheading>{title}</Subheading>
      {profiles.map(person => (
        <Profile {...person} />
      ))}
    </Spacing>
  ) : null;
}

export function CompanyPeople({
  company: { founders, peopleProgressing },
}: SectionProps): React.ReactElement {
  return (
    <Spacing size={0}>
      <Profiles title="Leadership" profiles={founders} />
      <Profiles title="People progressing" profiles={peopleProgressing} />
    </Spacing>
  );
}
