import styled, { css, up } from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { Card, Heading, Text, VerticalSpacing } from "@otta/design";

const Container = styled(Card)`
  background-color: yellow-100;
  padding: 0;

  // Using css to hide the card on mobile to avoid using js
  display: none;
  align-items: center;

  ${up(
    "tablet",
    css`
      display: flex;
    `
  )};
`;

const TextContainer = styled.div`
  padding: 0 xl;
`;

const Image = styled.img`
  object-fit: contain;
  height: ${pxToRem(200)};
`;

const CARD_COPY = {
  END_OF_MATCHES: {
    title:
      "You've seen all the candidates that match your diversity preferences",
    subTitle: "View other candidates below...",
  },
  NO_MATCHES: {
    title: "No candidates currently match your diversity preferences",
    subTitle: "Check back later or view other candidates below...",
  },
};

type CardType = "NO_MATCHES" | "END_OF_MATCHES";

const IMG_LINK = "https://static.otta.com/images/hire-app/detail_icon.png";

export function NoMoreDiversityMatchesCard({
  cardType,
}: {
  cardType: CardType;
}): React.ReactElement {
  return (
    <Container>
      <TextContainer>
        <VerticalSpacing>
          <Heading size={3} align="left">
            {CARD_COPY[cardType].title}
          </Heading>
          <Text align="left"> {CARD_COPY[cardType].subTitle}</Text>
        </VerticalSpacing>
      </TextContainer>
      <Image src={IMG_LINK} alt="diversity-banner" />
    </Container>
  );
}
