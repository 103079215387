import { useParams } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { BroadcastOverview } from "./BroadcastOverview";

import { JobFeature } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";
import { Loading } from "@otta/shared-components";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { useJobAuthorization } from "@hire/providers/authorization";

const Container = styled(ResponsiveCenteredContainer)`
  max-width: ${pxToRem(1200)};
`;

export const Broadcast = () => {
  const params = useParams<"jobId">();

  const jobId = params.jobId as string;

  const { granted, loading } = useJobAuthorization(JobFeature.Broadcast);

  if (loading) {
    return <Loading />;
  }
  return (
    <Container>
      {granted ? <BroadcastOverview jobId={jobId} /> : null}
    </Container>
  );
};
