import styled from "@xstyled/styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { ApolloQueryResult, useMutation } from "@apollo/client";

import { JobSlotsModal } from "../JobSlotsModal";
import { useJobsList } from "../../useJobsList";
import { JobListMode } from "../../statusTypes";

import { Button, Checkbox, FieldWrapper, Spacing, Text } from "@otta/design";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import {
  Feature,
  JobsListQuery,
  UpsertJobSlotChoicesDocument,
} from "@hire/schema";
import { handleMutationError } from "@hire/errors";
import { Icon } from "@otta/icons";
import { Link } from "@hire/components/links/Link";
import { useAuthorizations } from "@hire/providers/authorization";

const ButtonContainer = styled.div`
  margin-top: xl;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

const StyledFieldWrapper = styled(FieldWrapper)`
  // compensate for the added margin within FieldWrapper
  margin-left: -0.75rem;
  max-height: ${pxToRem(400)};
  overflow-y: auto;
`;

const JobRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

function JobOptions({
  jobs,
  companySlug,
  totalSlots,
  refetch,
  refetchLoading,
  canSelfServe,
}: {
  jobs: { id: string; title: string; isPaused: boolean; externalId: string }[];
  companySlug: string;
  totalSlots: number;
  refetch: () => Promise<ApolloQueryResult<JobsListQuery>>;
  refetchLoading: boolean;
  canSelfServe: boolean;
}) {
  const [submit, { loading }] = useMutation(UpsertJobSlotChoicesDocument, {
    onError: handleMutationError,
  });

  const [items, setItems] = useState(
    jobs.map(({ isPaused, ...rest }) => ({
      checked: !isPaused,
      ...rest,
    }))
  );
  const selectedItems = useMemo(
    () => items.filter(({ checked }) => checked),
    [items]
  );

  const toggleJob = useCallback(
    (id: string) => () => {
      setItems(
        items.map(i => {
          if (i.id === id) {
            return { ...i, checked: !i.checked };
          }
          return i;
        })
      );
    },
    [items, setItems]
  );

  const navigate = useNavigate();

  const onSave = useCallback(async () => {
    await submit({
      variables: {
        jobIds: items.filter(({ checked }) => checked).map(({ id }) => id),
      },
    });
    await refetch();
    navigate(`/${companySlug}/jobs`);
  }, [refetch, navigate, companySlug, submit, items]);

  const error = useMemo(() => {
    if (canSelfServe && selectedItems.length > totalSlots) {
      return `Select ${totalSlots} job${
        totalSlots > 1 ? "s" : ""
      }. Buy more job slots to select more.`;
    }

    if (selectedItems.length !== totalSlots) {
      const slotsToFill = Math.min(totalSlots, jobs.length);
      return `Select ${slotsToFill} job${slotsToFill > 1 ? "s" : ""}.`;
    }
  }, [selectedItems.length, totalSlots]);

  return (
    <div>
      <StyledFieldWrapper required fieldError={error}>
        {() =>
          items.map(({ id, title, checked, externalId }) => (
            <JobRow>
              <Checkbox
                label={title}
                checked={checked}
                onChange={toggleJob(id)}
              />
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Link
                  to={`${
                    import.meta.env.VITE_SEARCH_APP_HOST
                  }/jobs/${externalId}`}
                  size={-1}
                  style={{ paddingRight: modularScale(-6), fontWeight: "200" }}
                >
                  View job
                </Link>
                <Icon icon="external" />
              </div>
            </JobRow>
          ))
        }
      </StyledFieldWrapper>
      <ButtonContainer>
        <StyledButton
          level="primary"
          disabled={
            !!error || loading || refetchLoading || selectedItems.length === 0
          }
          onClick={onSave}
        >
          Save
        </StyledButton>
        <StyledButton
          level="secondary"
          as={Link}
          to={`/${companySlug}/jobs`}
          style={{ textDecoration: "none" }}
        >
          Cancel
        </StyledButton>
      </ButtonContainer>
    </div>
  );
}

export const ChooseJobSlots = () => {
  const { companySlug } = useParams<"companySlug">();

  const { jobs, company, loading, refetch } = useJobsList(JobListMode.ALL_JOBS);
  const { features, loading: featuresLoading } = useAuthorizations([
    Feature.JobSlots,
    Feature.JobSlotCheckout,
  ]);

  if (loading || !jobs || !companySlug || featuresLoading) {
    return null;
  }

  const totalSlots = company?.jobSlotData?.total ?? 0;

  return (
    <JobSlotsModal
      name="choose-job-slots"
      redirectOnClose={`/${companySlug}/jobs`}
    >
      <Spacing>
        <Spacing size={-2}>
          <Text size={2} bold>
            Swap published jobs
          </Text>
          <Text style={{ color: palette.grayscale.shade600 }}>
            You have {totalSlots} job slot{totalSlots > 1 ? "s" : ""}. Select
            the job{totalSlots > 1 ? "s" : ""} you want to publish.
          </Text>
        </Spacing>
        <JobOptions
          jobs={jobs}
          companySlug={companySlug}
          totalSlots={totalSlots}
          refetch={refetch}
          refetchLoading={loading}
          canSelfServe={
            (features.get(Feature.JobSlots)?.granted &&
              features.get(Feature.JobSlotCheckout)?.granted) ??
            false
          }
        />
      </Spacing>
    </JobSlotsModal>
  );
};
