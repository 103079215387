import { Link } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { Icon } from "@otta/icons";
import { Text } from "@otta/design";

const BreadCrumbsContainer = styled.div`
  display: flex;
  margin-bottom: xl;
`;

const BreadcrumbLink = styled(Link)<{
  currentCrumb: boolean;
}>`
  color: blue-600;
  font-size: 0.83rem;
  text-decoration: ${({ currentCrumb }) =>
    currentCrumb ? "underline" : "none"};
`;

const TextBreadcrumb = styled(Text).attrs({ size: -1 })`
  color: gray-600;
`;

const ArrowIcon = styled(Icon)`
  margin: 0 sm;
  color: gray-600;
`;

export const BreadCrumbs = ({
  currentBreadcrumb,
}: {
  currentBreadcrumb: string;
}) => {
  const breadcrumbs = [
    { name: "Company", link: "/quiz/company" },
    { name: "Account", link: "/quiz/signup" },
    { name: "Payment", link: "/quiz" },
  ];

  const currentCrumbIndex = breadcrumbs.findIndex(
    breadcrumb => breadcrumb.name === currentBreadcrumb
  );

  return (
    <BreadCrumbsContainer>
      {breadcrumbs.map((breadcrumb, index) => (
        <>
          {index <= currentCrumbIndex ? (
            <BreadcrumbLink
              currentCrumb={breadcrumb.name === currentBreadcrumb}
              to={breadcrumb.link}
            >
              {breadcrumb.name}
            </BreadcrumbLink>
          ) : (
            <TextBreadcrumb>{breadcrumb.name}</TextBreadcrumb>
          )}
          {index !== breadcrumbs.length - 1 && (
            <ArrowIcon icon="chevron-right" />
          )}
        </>
      ))}
    </BreadCrumbsContainer>
  );
};
