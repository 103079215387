import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";
import { Label, Spacing, Text } from "@otta/design";

const Container = styled.div`
  text-align: left;
  width: 100%;
`;
const ContentWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  gap: sm;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: sm;
`;

const StyledLabel = styled(Label)<{ size: number }>`
  display: flex;
  align-items: center;
  font-size: ${({ size }) => modularScale(size)};
`;

const RemoveButton = styled(Text)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const OptionalText = styled(Text)`
  font-style: italic;
  color: gray-600;
`;

const GuidanceText = styled(Text)`
  color: gray-600;
  margin-top: sm;
  margin-bottom: sm;
`;

export const MoreInfoFieldWrapper = ({
  label,
  children,
  fieldName,
  optional = false,
  guidance,
  small,
  onRemoveField: handleRemoveField,
}: {
  label: string;
  children: React.ReactElement;
  fieldName?: string;
  optional?: boolean;
  guidance?: string;
  small?: boolean;
  onRemoveField?: () => void;
}) => {
  const fontSize = small ? -1 : 0;
  return (
    <Container data-testid={`${fieldName}-wrapper`}>
      <Spacing size={-6}>
        <ContentWrapper data-testid={`label-wrapper-${fieldName}`}>
          <TextWrapper>
            <StyledLabel htmlFor={fieldName} size={fontSize}>
              {label}
            </StyledLabel>
            {optional && <OptionalText>optional</OptionalText>}
          </TextWrapper>
          {guidance && <GuidanceText>{guidance}</GuidanceText>}
          {!!handleRemoveField && (
            <RemoveButton
              role="button"
              size={fontSize}
              onClick={handleRemoveField}
              bold
            >
              Remove
            </RemoveButton>
          )}
        </ContentWrapper>
        {children}
      </Spacing>
    </Container>
  );
};
