import { useLocalStorage } from "react-use";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";

import { SourcingPreferencesFieldProps } from "./interfaces";
import { MustIncludeAllKeywordsField } from "./MustIncludeAllKeywordsField";

import { Icon } from "@otta/icons";
import {
  VerticalSpacing,
  Label,
  Text,
  Clickable,
  Input,
  Modal,
  Spacing,
} from "@otta/design";
import { MultiTextInput } from "@hire/components/input/MultiTextInput";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";
import { modularScale } from "@otta/design-tokens";
import { useExperiment } from "@otta/experiments/client";
import { Experiment } from "@hire/constants/experiments";

enum Mode {
  SIMPLE = "simple",
  ADVANCED = "advanced",
}

const Switch = styled(Clickable)`
  font-size: ${modularScale(-1)};
  text-decoration: underline;
`;

const Item = styled.div`
  flex-direction: column;
  display: flex;
  gap: 0.5rem;
`;

const ModalContent = styled.div`
  flex-direction: column;
  overflow-y: auto;
  padding: 1.5rem;
  display: flex;
  gap: 1.5rem;
`;

const Code = styled.code`
  background-color: gray-100;
  color: red-600;
  font-family: monospace;
`;

const SlightlyWiderModal = styled(Modal)`
  max-width: 40rem;
`;

function HelpModal() {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Switch
        data-analytics-id="advanced-query-help"
        onClick={() => setVisible(true)}
      >
        Advanced query help
      </Switch>
      <SlightlyWiderModal open={visible} onOpenChange={v => setVisible(v)}>
        <ModalContent>
          <Text as={"h4"} size={2}>
            Advanced queries
          </Text>
          <Item>
            <Text bold>
              Separate keywords with spaces to find profiles containing them
              all.
            </Text>
            <Text>
              <Code>javascript react</Code>
            </Text>
            <Text>
              Will match only profiles containing <strong>both</strong>{" "}
              "javascript" and "react".
            </Text>
          </Item>
          <Item>
            <Text bold>
              Separate keywords with <Code>OR</Code> to find profiles containing
              any of them.
            </Text>
            <Text>
              <Code>javascript OR react</Code>
            </Text>
            <Text>
              Will match profiles containing <strong>either</strong>{" "}
              "javascript" or "react".
            </Text>
          </Item>
          <Item>
            <Text bold>Use quotes to search for exact phrases.</Text>
            <Text>
              <Code>"software engineer"</Code> (in quotes)
            </Text>
            <Text>
              Will match "software engineer" but not "engineer of software".
            </Text>
          </Item>
          <Item>
            <Text bold>Use brackets to control precedence.</Text>
            <Text>
              <Code>(tax or forensic) accountant</Code>
            </Text>
            <Text>Will match "tax accountant" and "forensic accountant".</Text>
          </Item>
          <Item>
            <Text bold>
              Use <Code>NOT</Code> to exclude profiles containing keywords
            </Text>
            <Text>
              <Code>NOT javascript</Code>
            </Text>
            <Text>
              Will only match profiles that <strong>do not</strong> include
              "javascript".
            </Text>
          </Item>
        </ModalContent>
      </SlightlyWiderModal>
    </>
  );
}

function FieldLabel() {
  return (
    <TooltipWithIcon
      content="Search single words, multiple words, or multi-word phrases (e.g. “product design”)"
      icon={<Icon icon="circle-info" size={1} />}
    >
      <Label htmlFor="keywords">
        <Text bold>Keyword search</Text>
      </Label>
    </TooltipWithIcon>
  );
}

function SimpleKeywordField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <VerticalSpacing size={-4}>
      <FieldLabel />
      <MultiTextInput
        value={preferences.keywords ?? []}
        placeholder="Enter keywords"
        minLength={1}
        onChange={vals => {
          onChange({ keywords: vals });
        }}
      />
    </VerticalSpacing>
  );
}

function AdvancedKeywordField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  const [value, setValue] = useState(preferences.queryString);

  useEffect(() => {
    const int = setTimeout(() => onChange({ queryString: value }), 500);
    return () => clearTimeout(int);
  }, [value, onChange]);

  return (
    <Spacing size={-4}>
      <FieldLabel />
      <Input
        minLength={1}
        placeholder='Lead OR "Head of" OR Principal'
        defaultValue={value ?? ""}
        onChange={e => setValue(e.target.value)}
      />
      <HelpModal />
    </Spacing>
  );
}

export function KeywordsField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  const { variant } = useExperiment(Experiment.BooleanSearch);

  const [userMode, setMode] = useLocalStorage(
    "otta-sourcing-mode",
    Mode.SIMPLE
  );

  const mode = useMemo(
    () =>
      preferences.queryString
        ? Mode.ADVANCED
        : preferences.keywords?.length
        ? Mode.SIMPLE
        : variant !== "variant"
        ? Mode.SIMPLE
        : userMode,
    [preferences, userMode, variant]
  );

  useEffect(() => {
    if (mode === Mode.ADVANCED && preferences.keywords?.length) {
      onChange({ keywords: undefined });
    } else if (mode === Mode.SIMPLE && preferences.queryString) {
      onChange({ queryString: undefined });
    }
  }, [mode, onChange, preferences]);

  const toggleMode = useCallback(() => {
    if (userMode === Mode.SIMPLE) {
      onChange({ keywords: undefined });
      setMode(Mode.ADVANCED);
    } else {
      onChange({ queryString: undefined });
      setMode(Mode.SIMPLE);
    }
  }, [setMode, userMode, onChange]);

  const oppositeMode = mode === Mode.ADVANCED ? "classic" : "advanced";

  return (
    <Spacing size={-1}>
      {mode === Mode.SIMPLE ? (
        <Spacing size={1}>
          <SimpleKeywordField preferences={preferences} onChange={onChange} />
          <MustIncludeAllKeywordsField
            preferences={preferences}
            onChange={onChange}
          />
        </Spacing>
      ) : (
        <AdvancedKeywordField preferences={preferences} onChange={onChange} />
      )}
      {variant === "variant" && (
        <Switch
          onClick={() => toggleMode()}
          data-analytics-id={`switch-to-${oppositeMode}`}
        >
          Switch to {oppositeMode} mode
        </Switch>
      )}
    </Spacing>
  );
}
