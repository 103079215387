import styled, { css, up, down } from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { useStripeCheckoutLink } from "../../../JobsList/JobSlots/useStripeCheckoutLink";

import { CandidateMatchCount } from "./CandidateMatchCount";

import { Button, Heading, List, Spacing, Text } from "@otta/design";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import {
  CatalogueProductId,
  Currency,
  CurrentUserDocument,
  ProductQuoteDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { formatCurrency } from "@hire/util/currencies";
import { Link } from "@hire/components/links";
import { useExperiment } from "@otta/experiments/client";
import { Experiment } from "@hire/constants/experiments";
import { LogoHeader } from "@hire/components/layout/QuizLayout";

const StickyContainer = styled.div`
  background-color: white;
  border-top: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  inset: auto 0 0 0;
  position: fixed;
  padding: 1rem;
  ${up(
    "desktop",
    css`
      border-top: none;
      position: static;
      padding: 0;
    `
  )}
`;

const ButtonContainer = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: row;
  ${up(
    "desktop",
    css`
      flex-direction: column;
    `
  )}
`;

const Main = styled.main`
  background-color: white;
`;

const PageContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 4rem);
  padding-bottom: 200;
  flex-direction: column;

  ${up(
    "desktop",
    css`
      flex-direction: row;
      padding-bottom: unset;
    `
  )}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 lg;
  ${up(
    "desktop",
    css`
      align-items: center;
    `
  )}
`;

const ContinueButton = styled(Button)`
  text-decoration: none;
  width: 100%;

  ${down(
    "desktop",
    css`
      padding: 0.5rem 1rem;
    `
  )}
`;

const ColumnInner = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  flex-direction: column;
  gap: xl;
  ${up(
    "desktop",
    css`
      max-width: ${pxToRem(400)};
      gap: 40;
      padding-top: 5rem;
    `
  )}
`;

const PageHeading = styled(Heading)`
  max-width: ${pxToRem(400)};
  font-size: ${modularScale(1)};
  ${up(
    "desktop",
    css`
      font-family: "Fann Grotesque", sans-serif;
      font-size: ${modularScale(3)};
      max-width: ${pxToRem(400)};
    `
  )}
`;

function PricingInformation() {
  const { data } = useQuery(CurrentUserDocument);
  const { callback, loading } = useStripeCheckoutLink("quiz/success");
  const { variant } = useExperiment(Experiment.CandidateCount);
  const navigate = useNavigate();
  const loggedIn = !!data?.me;

  const { data: productQuoteData, loading: productQuoteLoading } = useQuery(
    ProductQuoteDocument,
    {
      variables: {
        quantity: 1,
        productId: CatalogueProductId.JobSlotMonthly,
      },
    }
  );

  function getValueForCore() {
    return productQuoteData?.catalogue?.product?.quote
      ? formatCurrency(
          productQuoteData.catalogue.product.quote.netTotal,
          productQuoteData.catalogue.product.quote.currency
        )
      : formatCurrency("199", Currency.Gbp);
  }

  const formattedCorePriceValue = getValueForCore();

  if (productQuoteLoading) {
    return <Loading />;
  }

  return (
    <Column>
      <ColumnInner>
        <div>
          <Spacing size={0}>
            <PageHeading>
              For {formattedCorePriceValue} per month, you get:
            </PageHeading>
            <List type="positive" style={{ maxWidth: "343px" }}>
              <li>
                <Text inline bold>
                  1 job slot.
                </Text>{" "}
                Buy more or cancel anytime.
              </li>
              <li>
                Candidates{" "}
                <Text inline bold>
                  {" "}
                  twice as likely to progress to interview{" "}
                </Text>{" "}
                compared to LinkedIn.
              </li>
              <li>
                <Text inline bold>
                  Diverse candidates.
                </Text>{" "}
                53% female or non-binary and 38% from under-represented ethnic
                groups.
              </li>
              <li>
                <Text inline bold>
                  Unlimited
                </Text>{" "}
                users.
              </li>
            </List>
          </Spacing>
        </div>
        <StickyContainer>
          <Spacing size={-2}>
            <ButtonContainer>
              {variant === "variant" && (
                <ContinueButton
                  data-analytics-id="try-another-job"
                  to="/quiz/job-performance"
                  level="secondary"
                  as={Link}
                >
                  Try another job
                </ContinueButton>
              )}
              <ContinueButton
                onClick={() => {
                  if (!loggedIn) {
                    navigate("/quiz/company");
                  } else {
                    callback();
                  }
                }}
                level="primary"
              >
                {loading ? "Loading..." : "Continue"}
              </ContinueButton>
            </ButtonContainer>
            <Text size={-1} align="center">
              <strong>No commitment. </strong>
              Pay monthly, cancel online anytime.
            </Text>
          </Spacing>
        </StickyContainer>
      </ColumnInner>
    </Column>
  );
}

export function JobPerformanceResults() {
  return (
    <Main>
      <LogoHeader />
      <PageContainer>
        <CandidateMatchCount />
        <PricingInformation />
      </PageContainer>
    </Main>
  );
}
