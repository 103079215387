import styled, { css } from "@xstyled/styled-components";

import {
  palette,
  modularScale,
  pxToRem,
  borderRadiusSmall,
} from "@otta/design-tokens";
import { Icon, OttaIconsId } from "@otta/icons";
import { Text, Tooltip } from "@otta/design";

const Tag = styled.div<{
  color?: string;
  removePadding: boolean;
  center: boolean;
}>`
  display: flex;
  white-space: nowrap;
  position: relative;
  width: fit-content;
  align-items: center;

  padding: ${({ removePadding }) =>
    removePadding
      ? `0 0 0 ${pxToRem(10)}`
      : `${modularScale(-8)} ${modularScale(-4)}`};
  border-radius: ${pxToRem(borderRadiusSmall)};
  color: black;
  background-color: ${({ color }) => color ?? palette.extended.blue.shade200};

  i {
    margin-right: xs;
  }

  ${({ center }) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;

const RightSection = styled.div`
  float: right;
  background: ${palette.brand.white};
  margin-left: xs;
  padding: 5 10;
  border: ${pxToRem(1)} solid ${palette.brand.yellow};
  border-radius: 0 ${pxToRem(borderRadiusSmall)} ${pxToRem(borderRadiusSmall)} 0;
`;

export const RoundedTag = ({
  content,
  color,
  textColor,
  fontSize = -1,
  iconSize = -1,
  icon,
  rightSectionContent,
  bold = false,
  center = false,
  hoverText,
  className,
}: {
  icon?: OttaIconsId;
  iconSize?: number;
  fontSize?: number;
  content: string | React.ReactElement;
  color?: string;
  textColor?: string;
  rightSectionContent?: string | number;
  bold?: boolean;
  center?: boolean;
  hoverText?: string;
  className?: string;
}) => {
  const tag = (
    <Tag
      color={color}
      removePadding={!!rightSectionContent}
      center={center}
      data-testid="rounded-tag"
      className={className}
    >
      {icon && <Icon icon={icon} size={iconSize} />}
      {typeof content === "string" ? (
        <Text size={fontSize} color={textColor} bold={bold}>
          {content}
        </Text>
      ) : (
        content
      )}
      {rightSectionContent && (
        <RightSection>
          <Text size={fontSize} color={textColor} bold={bold}>
            {rightSectionContent}
          </Text>
        </RightSection>
      )}
    </Tag>
  );

  return hoverText ? <Tooltip content={hoverText}>{tag}</Tooltip> : tag;
};
