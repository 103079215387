import styled from "@xstyled/styled-components";
import { format, parseISO } from "date-fns";

import { CompanyEmployeeSurveyFragment } from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";

const Container = styled.div`
  text-align: left;
`;

const EndorsementInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10;
  border-radius: 50%;
  background-color: beige-300;
  height: ${pxToRem(44)};
  width: ${pxToRem(44)};
`;

interface EndorsementProps {
  endorsement: CompanyEmployeeSurveyFragment;
}

export const Endorsement = ({
  endorsement,
}: EndorsementProps): React.ReactElement => {
  const { jobFunction, submittedAt, responses } = endorsement;
  const profileLetter = endorsement.jobFunction.value.charAt(0);
  const formattedDate = submittedAt
    ? format(parseISO(submittedAt), "MMM yyyy")
    : undefined;

  return (
    <Container data-testid="endorsement">
      <Spacing size={3}>
        <EndorsementInfo>
          <Profile data-testid="profile-circle">{profileLetter}</Profile>
          <div data-testid="employee-info-section">
            <Text>
              Employee in the&nbsp;
              <strong>{jobFunction.value}</strong>
              &nbsp;team
            </Text>
            {formattedDate && (
              <Text color={palette.grayscale.shade600}>{formattedDate}</Text>
            )}
          </div>
        </EndorsementInfo>
        <Spacing size={2}>
          {responses.map(response => (
            <Spacing key={response.id}>
              <Text bold>{response.category.name}</Text>
              <Text>{response.content}</Text>
            </Spacing>
          ))}
        </Spacing>
      </Spacing>
    </Container>
  );
};
