import { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { UNFREEZE_JOB_LOCAL_STORAGE_ENTRY } from "../JobSlots/Content";
import { JobListStatus } from "../statusTypes";

import { Feature, JobFeature, JobPreviewFragment } from "@hire/schema";
import { JobNotificationSubscription } from "@hire/components/JobNotificationSubscription";
import { RectangleTag } from "@hire/components/tags/RectangleTag";
import {
  palette,
  modularScale,
  pxToRem,
  borderRadiusSmall,
} from "@otta/design-tokens";
import { Button, Card, Spacing, Text, Tooltip } from "@otta/design";
import { useAuthorization } from "@hire/providers/authorization";
import { Icon } from "@otta/icons";
import { pushAnalyticsEvent } from "@otta/analytics";

const Title = styled(Text)`
  font-weight: bold;
`;

const HeadingContainer = styled.div`
  display: grid;
  column-gap: sm;
  width: 100%;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: sm;
  align-self: stretch;
  flex-wrap: wrap;
`;

const Subtitle = styled(Text).attrs({ bold: true, size: -1 })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCard = styled(Card)<{ isEmpty?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: lg;
  padding: lg;
  width: ${pxToRem(344)};
  border: 1px ${({ isEmpty = false }) => (isEmpty ? "dashed" : "solid")}
    ${palette.grayscale.shade200};
  border-radius: ${pxToRem(borderRadiusSmall)};

  &:hover {
    background-color: gray-50;
    border: 1px solid ${palette.brand.black};
  }
  ${({ isEmpty = false }) =>
    isEmpty && `background: ${palette.grayscale.shade50}`}
`;

const StatWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: sm;
`;

const StatsContainer = styled.div`
  display: flex;
  gap: lg;
`;

const StyledLink = styled(Link)`
  color: black;
  display: grid;
  gap: lg;
  text-align: left;
  text-decoration: none;
  width: 100%;
`;

const ThawButton = styled(Button)`
  margin-top: auto;
  align-self: flex-start;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  &[hidden] {
    visibility: hidden;
  }
`;

const JobStat = ({ label, stat }: { label: string; stat: number }) => {
  return (
    <StatWrapper>
      <Text style={{ textTransform: "capitalize" }}>{label}</Text>
      <Text data-testid={label} bold>
        {stat}
      </Text>
    </StatWrapper>
  );
};

interface JobPreviewProps extends JobPreviewFragment {
  potentialCandidates?: number;
  status: JobListStatus;
}

export const JobPreview = ({
  title,
  id,
  status,
  totalNumberApplicants,
  numberNewApplicants: newApplications,
  numberUnreadMessages: unread,
  numberCandidatesShortlisted,
  subtitle,
  externalId,
  subscribed,
  hasAccess,
  potentialCandidates,
  salaryRange,
  oteSalaryRange,
}: JobPreviewProps) => {
  const { granted: hasCheckout } = useAuthorization(Feature.JobSlotCheckout);
  const isMissingSalary =
    status === JobListStatus.PUBLISHED && !salaryRange && !oteSalaryRange;
  const hasNewApplications = !!newApplications && newApplications > 0;
  const hasNewMessages = !!unread && unread > 0;

  const seenUnfreezeModal =
    localStorage.getItem(UNFREEZE_JOB_LOCAL_STORAGE_ENTRY) === "true";

  const hasPipeline = useMemo(
    () => !!hasAccess.find(a => a.feature === JobFeature.Pipeline)?.granted,
    [hasAccess]
  );

  const hasSourcing = useMemo(
    () => !!hasAccess.find(a => a.feature === JobFeature.Sourcing)?.granted,
    [hasAccess]
  );

  const stats = useMemo(
    () =>
      [
        hasPipeline && { label: "applied", stat: totalNumberApplicants ?? 0 },
        hasSourcing && {
          label: "shortlisted",
          stat: numberCandidatesShortlisted ?? 0,
        },
      ].filter((s): s is { label: string; stat: number } => Boolean(s)),
    [
      hasPipeline,
      hasSourcing,
      numberCandidatesShortlisted,
      totalNumberApplicants,
    ]
  );

  const hasBadges = hasNewMessages || (hasPipeline && hasNewApplications);

  return (
    <StyledCard>
      <StyledLink
        title={`Preview the ${title} job`}
        data-testid="job-preview"
        to={
          status === JobListStatus.REVIEW
            ? `./${externalId}/edit`
            : `./${externalId}`
        }
        relative="path"
      >
        <HeadingContainer>
          <Title>{title}</Title>
          {subtitle && (
            <Subtitle style={{ marginTop: modularScale(-4) }}>
              {subtitle ? subtitle : " "}
            </Subtitle>
          )}
        </HeadingContainer>
        {hasBadges && (
          <TagContainer>
            {hasPipeline && hasNewApplications && (
              <RectangleTag
                color={palette.brand.yellow}
                icon="apply"
                content={`${newApplications} new application${
                  newApplications > 1 ? "s" : ""
                }`}
              />
            )}
            {hasNewMessages && (
              <RectangleTag
                color={palette.brand.green}
                icon="response-rate"
                content={`${unread} unread message${unread > 1 ? "s" : ""}`}
              />
            )}
          </TagContainer>
        )}
        <div>
          <Spacing>
            {status === JobListStatus.UNPUBLISHED && (
              <Tooltip
                content="A live calculation of active candidates with search preferences that match this job's requirements"
                placement="bottom"
                style={{ padding: pxToRem(8) }}
              >
                <IconWrapper hidden={(potentialCandidates ?? 0) === 0}>
                  <Icon
                    icon="users"
                    size={-1}
                    style={{ marginRight: modularScale(-8) }}
                  />
                  <Text size={-1} bold>
                    {(potentialCandidates ?? 0).toLocaleString()} matching
                    candidates
                  </Text>
                </IconWrapper>
              </Tooltip>
            )}
            {isMissingSalary && (
              <RectangleTag
                color={palette.extended.orange.shade200}
                icon="salary-off"
                content={`Missing salary`}
              />
            )}
            {stats.length > 0 && (
              <StatsContainer>
                {stats.map(({ label, stat }) => (
                  <JobStat key={`${label}-stat`} label={label} stat={stat} />
                ))}
              </StatsContainer>
            )}
            {hasPipeline && (
              <JobNotificationSubscription
                jobId={id}
                subscribed={!!subscribed}
              />
            )}
          </Spacing>
        </div>
      </StyledLink>
      {status === JobListStatus.UNPUBLISHED && hasCheckout && (
        <ThawButton
          as={Link}
          level="secondary"
          data-analytics-id="unfreeze-this-job"
          to={`${seenUnfreezeModal ? "pricing" : "unfreeze-job"}/${externalId}`}
        >
          Publish this job
        </ThawButton>
      )}
    </StyledCard>
  );
};

export const JobPreviewEmptySlot = ({
  showCTA = false,
}: {
  showCTA?: boolean;
}) => (
  <StyledCard isEmpty>
    <Text bold>Empty job slot</Text>
    {showCTA && (
      <ThawButton
        data-testid="create-new-job-empty-slot-card"
        as={Link}
        level="primary"
        to="create"
        onClick={() => {
          pushAnalyticsEvent({
            eventName: "Company Recruiter Clicked",
            name: "create-new-job",
            section: "empty-job-slot",
          });
        }}
      >
        Create new job
      </ThawButton>
    )}
  </StyledCard>
);
