import styled, { css, down } from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { createSearchParams, useNavigate } from "react-router-dom";

import { Form, FormValues } from "./Form";

import { palette, pxToRem } from "@otta/design-tokens";
import { Card, Heading, Spacing, Text } from "@otta/design";
import { QuizFieldValuesDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { RoundedTag } from "@hire/components/tags/RoundedTag";
import { LogoHeader } from "@hire/components/layout/QuizLayout";

const Main = styled.main`
  background-color: white;
  justify-content: stretch;
  flex-direction: column;
  min-height: 100vh;
  display: flex;
`;

const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const LeftColumn = styled.div`
  width: ${pxToRem(650)};
  background-color: beige-200;
  display: flex;
  align-items: center;
  ${down(
    "desktop",
    css`
      display: none;
    `
  )}
`;

const RightColumn = styled.div`
  align-self: center;
  margin: 2rem auto;
  padding: 1rem;
`;

const TitleCard = styled(Card)`
  background-color: beige-200;
  padding: 40 4xl xl 4xl;
`;

const BubbleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: sm;
`;

const StyledRoundedTag = styled(RoundedTag).attrs({
  bold: true,
})`
  margin: 0 sm;
  padding: sm md;
  border-radius: 4rem;
`;

export function JobPerformance() {
  const navigate = useNavigate();
  const { data: options, loading } = useQuery(QuizFieldValuesDocument);

  const handleFormSubmission = (values: FormValues) => {
    navigate({
      pathname: "/quiz/job-performance/results",
      search: createSearchParams({
        locations: values.locations ?? [],
        experience: values.experience?.map(y => `${y}`) ?? [],
        languages: values.languages ?? [],
        functions: values.subFunction ?? "",
      }).toString(),
    });
  };
  if (loading || !options) {
    return <Loading />;
  }

  return (
    <Main>
      <LogoHeader />
      <PageContainer>
        <LeftColumn>
          <TitleCard style={{ backgroundColor: palette.beige.shade200 }}>
            <BubbleContainer>
              <StyledRoundedTag
                icon="steps"
                content="Experience"
                color={palette.brand.blue}
              />
            </BubbleContainer>
            <BubbleContainer>
              <StyledRoundedTag
                icon="location"
                content="Location"
                color={palette.brand.pink}
              />
              <StyledRoundedTag
                icon="industry"
                content="Function"
                color={palette.brand.orange}
              />
              <StyledRoundedTag
                icon="response-rate"
                content="Language"
                color={palette.brand.green}
              />
            </BubbleContainer>
            <Heading size={5} style={{ marginTop: pxToRem(32) }}>
              We'll only show your job to candidates with matching preferences
            </Heading>
            <Text size={3} style={{ marginTop: pxToRem(24) }}>
              This includes job function, experience level, location and many
              more
            </Text>
          </TitleCard>
        </LeftColumn>
        <RightColumn>
          <Spacing size={2}>
            <Heading size={3} bold>
              Tell us about the job you'd like to post
            </Heading>
            <Form options={options} onSubmit={v => handleFormSubmission(v)} />
          </Spacing>
        </RightColumn>
      </PageContainer>
    </Main>
  );
}
