import { useState } from "react";

import { useGetCompanyMembers } from "../../util/hooks";

import { SalaryVisibilityButton } from "./SalaryVisibilityButton";
import { SalaryVisibilityModal } from "./SalaryVisibilityModal";

import { Feature, JobFeature } from "@hire/schema";
import {
  useAuthorizations,
  useJobAuthorization,
} from "@hire/providers/authorization";

export default function SalaryVisibilityComponent({
  jobExternalId,
  thin,
  handleFieldChange,
}: {
  jobExternalId: string;
  thin?: boolean;
  handleFieldChange: (fieldName: string, value: boolean) => void;
}): React.ReactElement | null {
  const [showModal, setShowModal] = useState(false);

  const { features } = useAuthorizations([Feature.HideSalaryFromColleagues]);

  const { granted: canViewSalary } = useJobAuthorization(JobFeature.ViewSalary);

  const {
    currentUserId,
    companyMembers,
    recruiterIdsThatCanView,
    hiddenFromCount,
    hideSalary,
    loading,
    refetch,
    updatePermissions,
    updateVisibilityMutation,
  } = useGetCompanyMembers(jobExternalId);

  const handleClose = () => {
    refetch();
    setShowModal(false);
  };

  const handleChange = (userId: string) => {
    const userIds = recruiterIdsThatCanView.includes(userId)
      ? recruiterIdsThatCanView.filter(recruiterId => userId !== recruiterId)
      : [...recruiterIdsThatCanView, userId];

    updatePermissions({
      variables: {
        jobId: jobExternalId,
        userIds,
      },
    });
  };

  const handleToggle = (newValue: boolean) => {
    updateVisibilityMutation({
      variables: {
        jobId: jobExternalId,
        hideSalary: newValue,
      },
    });
    handleFieldChange("hideSalary", newValue);
    refetch();
  };

  if (loading || !canViewSalary) {
    return null;
  }

  const canHideSalaryFromColleagues = !!features.get(
    Feature.HideSalaryFromColleagues
  )?.granted;

  return (
    <>
      <SalaryVisibilityButton
        hideSalary={hideSalary}
        thin={!!thin}
        canViewSalary={!!canViewSalary}
        canHideAllSalary={!!canHideSalaryFromColleagues}
        hiddenFromCount={hiddenFromCount}
        handleShowModal={() => setShowModal(true)}
      />
      <SalaryVisibilityModal
        companyMembers={companyMembers}
        currentUserId={currentUserId}
        hideSalary={hideSalary}
        visibleToIds={recruiterIdsThatCanView}
        canHideAllSalary={!!canHideSalaryFromColleagues}
        onToggleSalaryVisibility={handleToggle}
        onChangeMemberVisibility={handleChange}
        onClose={handleClose}
        isOpen={showModal}
      />
    </>
  );
}
