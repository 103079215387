import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";

const Bezel = styled.div`
  flex-direction: column;
  background: ${palette.brand.black};
  justify-content: stretch;
  max-height: ${pxToRem(800)};
  border: 0.1rem solid ${palette.grayscale.shade200};
  padding: 6;
  border-radius: 40;
  position: relative;
  height: 75vh;
  display: flex;
`;

const ScrollContainer = styled.div`
  background-color: beige-200;
  border-radius: 32;
  overflow-y: scroll;
`;

export function Phone({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Bezel>
      <ScrollContainer>{children}</ScrollContainer>
    </Bezel>
  );
}
