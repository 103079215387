import styled from "@xstyled/styled-components";

import { ResponsiveCenteredContainer } from "./containers/ResponsiveCenteredContainer";

import { Icon } from "@otta/icons";
import { Button, Card, Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

const RedCircle = styled.div`
  background-color: red-600;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 0 auto;
`;

export const ReloadableError = ({
  title = "Something went wrong",
  body = "Check your internet connection and try again.",
  cta = "Try again",
  action,
}: {
  title?: string;
  body?: string;
  cta?: string;
  action: () => void;
}) => {
  return (
    <ResponsiveCenteredContainer maxWidth={440}>
      <Card>
        <Spacing size={2}>
          <RedCircle>
            <Icon
              icon="circle-exclamation"
              style={{ color: palette.brand.white }}
              size={4.5}
            />
          </RedCircle>
          <Spacing size={-1}>
            <Text size={1} bold>
              {title}
            </Text>
            <Text>{body}</Text>
          </Spacing>
          <Button level="primary" onClick={() => action()}>
            {cta}
          </Button>
        </Spacing>
      </Card>
    </ResponsiveCenteredContainer>
  );
};
